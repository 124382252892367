import { takeEvery, put, fork } from 'redux-saga/effects';

import actions from './actions';
import messageActions from '../../redux/message/actions';
import RestApi from '../../helpers/api';

const api = new RestApi('vet/clients-info');

export function* loadClientsInfo() {
  yield takeEvery(actions.LOAD_CLIENT_INFO, function* ({ payload }) {
    const result = yield api.load(payload);
    if (result.status === 200) {
      const {
        result: tableData,
        count,
        subscription,
        horseSubscription,
      } = result.data;
      yield put(
        actions.loadClientInfoSuccess({
          tableData,
          pagination: { total: count },
          petsSubscription: subscription.map(petSubscr => petSubscr.userId),
          horseSubscription: horseSubscription.map(
            horseSubscr => horseSubscr.userId
          ),
        })
      );
    } else {
      const message = result.data;
      yield put(actions.loadClientInfoError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export default function* rootSaga() {
  yield fork(loadClientsInfo);
}
