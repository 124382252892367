import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const SIGN_IN_ROUTE = '/signin';
const FORGOT_PASSWORD = '/forgotpassword';
const RESET_PASSWORD = '/resetpassword';

const RestrictedRoute = ({ component: Component, history, ...rest }) => {
  if (
    history.location.pathname === SIGN_IN_ROUTE ||
    history.location.pathname === FORGOT_PASSWORD ||
    history.location.pathname === RESET_PASSWORD
  ) {
    return null;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

const PublicRoutes = ({ history }) => {
  return (
    <ConnectedRouter history={history} noInitialPop>
      <Switch>
        <Route
          exact
          path="/"
          component={asyncComponent(() => import('./containers/Signin/Signin'))}
        />
        <Route
          path={SIGN_IN_ROUTE}
          component={asyncComponent(() => import('./containers/Signin/Signin'))}
        />
        <Route
          path={FORGOT_PASSWORD}
          component={asyncComponent(() =>
            import('./containers/ForgotPassword/ForgotPassword')
          )}
        />
        <RestrictedRoute history={history} component={App} />
      </Switch>
    </ConnectedRouter>
  );
};

export default PublicRoutes;
