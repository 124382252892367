import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Form, Input, Modal } from 'antd';
import Icon from '@ant-design/icons';

import profileActions from '../../redux/profile/actions';
import ConfirmDialogModal from '../../components/ConfirmDialogModal/ConfirmDialogModal';
import UserIcon from '../../components/Icons/UserIcon';
import helpers from '../../helpers/helperFunctions';
import GlobalStyle from '../../GlobalStyle';

let formDataImage = '';

const EditProfileModal = ({ closeModal, modalOptions }) => {
  const userData = modalOptions.modalData;

  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = useState(userData.profileImage);
  const [disabledSave, setDisabledSave] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  let imageInput = useRef(null);

  const addImage = () => {
    imageInput.current.click();
  };

  const editUserProfileHandler = value => {
    const difference = helpers.findDifference(userData, value);

    const editData = new FormData();

    for (const key in difference) {
      const element = difference[key];
      editData.append(key, element);
    }

    editData.append('user', userData.userId);

    if (formDataImage) {
      editData.append('image', formDataImage, formDataImage.name);
    }

    dispatch(
      profileActions.editProfile({
        editData,
        dispatch,
      })
    );

    closeModal();
  };

  const isDataIdentical = () => {
    const formValues = form.getFieldsValue();
    if (
      (!formDataImage &&
        formValues.name === userData.name &&
        formValues.email === userData.email) ||
      form.getFieldsError().some(({ errors }) => errors.length)
    ) {
      setDisabledSave(true);
      return;
    }
    setDisabledSave(false);
  };

  const onImageChangeHandler = event => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        setProfileImage(URL.createObjectURL(selectedFile));
      };

      reader.readAsDataURL(selectedFile);

      formDataImage = selectedFile;

      if (!form.getFieldsError().some(({ errors }) => errors.length)) {
        setDisabledSave(false);
      }
    }
  };

  const closeModalHandler = () => {
    if (!disabledSave) {
      setIsModalVisible(true);
    } else {
      closeModal();
    }
  };

  const confirmModalCloseHandler = () => {
    setIsModalVisible(false);
    closeModal();
  };

  return (
    <>
      <GlobalStyle />
      <Modal
        width={500}
        className="custom-modal edit-profile"
        title="Edit Profile"
        visible={modalOptions.isModalVisible}
        footer={[
          <Button key="Cancel" className="cancel" onClick={closeModalHandler}>
            CANCEL
          </Button>,
          <Button
            key="Save"
            htmlType="submit"
            form="edit-form"
            disabled={disabledSave}
          >
            SAVE
          </Button>,
        ]}
        okButtonProps={{ disabled: true }}
        onCancel={closeModalHandler}
      >
        <Form
          onFieldsChange={isDataIdentical}
          id="edit-form"
          form={form}
          layout="vertical"
          className="custom-form"
          onFinish={editUserProfileHandler}
        >
          <Form.Item label="Photo" htmlFor="image">
            <div onClick={addImage}>
              <input
                style={{ display: 'none' }}
                type="file"
                ref={imageInput}
                onChange={onImageChangeHandler}
                accept="image/*"
              />
              {userData.profileImage ? (
                <Avatar className="avatar" size={100} src={profileImage} />
              ) : (
                <Avatar
                  className="avatar"
                  size={50}
                  icon={<Icon component={UserIcon} />}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            initialValue={userData.name}
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
            initialValue={userData.email}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Last login"
            name="lastLogin"
            initialValue={userData.lastLogin}
          >
            <Input className="last-login" disabled />
          </Form.Item>
        </Form>
      </Modal>
      {isModalVisible && (
        <ConfirmDialogModal
          isModalVisible={isModalVisible}
          title="Confirm close"
          text="You’re about to cancel your changes. Are you sure you want to continue?"
          cancel={() => setIsModalVisible(false)}
          confirm={confirmModalCloseHandler}
        />
      )}
    </>
  );
};

export default EditProfileModal;
