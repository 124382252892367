const EditIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 13H13.125"
      stroke="#598AB3"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3125 2.6875C10.5611 2.43886 10.8984 2.29918 11.25 2.29918C11.4241 2.29918 11.5965 2.33347 11.7574 2.4001C11.9182 2.46673 12.0644 2.56439 12.1875 2.6875C12.3106 2.81062 12.4083 2.95678 12.4749 3.11763C12.5415 3.27849 12.5758 3.45089 12.5758 3.625C12.5758 3.79911 12.5415 3.97152 12.4749 4.13238C12.4083 4.29323 12.3106 4.43939 12.1875 4.5625L4.375 12.375L1.875 13L2.5 10.5L10.3125 2.6875Z"
      stroke="#598AB3"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
