import React from 'react';
import { Button, Modal } from 'antd';

import GlobalStyle from '../../GlobalStyle';

const ConfirmDialogModal = ({
  isModalVisible,
  cancel,
  title,
  text,
  confirm,
}) => {
  return (
    <>
      <GlobalStyle />
      <Modal
        width={500}
        className="custom-modal"
        closable={false}
        title={title}
        visible={isModalVisible}
        onCancel={cancel}
        maskClosable={false}
        footer={[
          <Button key="NO" type="primary" className="cancel" onClick={cancel}>
            No, cancel
          </Button>,
          <Button key="YES" type="primary" className="confirm" onClick={confirm}>
            Yes, I confirm
          </Button>,
        ]}
      >
        <p className="confirm-text">{text}</p>
      </Modal>
    </>
  );
};

export default ConfirmDialogModal;
