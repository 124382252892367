function echo() {}

export function keyBy(arrayOrIter, keySelector, valueMapper = echo) {
  const array = Array.isArray(arrayOrIter) ? arrayOrIter : Array.from(arrayOrIter);
  return array.reduce((map, item, i) => {
    const key = keySelector(item, i);
    map[key] = valueMapper(item, i);
    return map;
  }, {});
}

export function keyByProperty(array, keyName, valueMapper = echo) {
  return keyBy(array, item => item[keyName], valueMapper);
}
