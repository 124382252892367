import { createReducer } from '../../helpers/reducer';
import actions from './actions';
import moment from 'moment';

const initialState = {
  clinic: null,
  dailyStatistic: [],
  monthlyStatistic: [],
  date: moment(),
  loading: false,
};

function onLoadClinic(state) {
  return {
    ...state,
    loading: true,
  };
}
function onLoadClinicSuccess(state, { payload }) {
  return {
    ...state,
    clinic: payload,
    loading: false,
  };
}
function onLoadClinicError(state) {
  return { ...state, loading: false };
}

function onLoadStatistic(state) {
  return { ...state, loading: true };
}
function onLoadStatisticSuccess(state, { payload }) {
  const { dailyStatistic, monthlyStatistic } = payload;
  return { ...state, loading: false, dailyStatistic, monthlyStatistic };
}
function onLoadStatisticError(state) {
  return { ...state, loading: false };
}
function onChangeDate(state, { payload }) {
  return { ...state, date: payload };
}

export default createReducer(initialState, {
  [actions.LOAD_CLINIC_ERROR]: onLoadClinic,
  [actions.LOAD_CLINIC_SUCCESS]: onLoadClinicSuccess,
  [actions.LOAD_CLINIC_ERROR]: onLoadClinicError,
  [actions.LOAD_STATISTIC]: onLoadStatistic,
  [actions.LOAD_STATISTIC_SUCCESS]: onLoadStatisticSuccess,
  [actions.LOAD_STATISTIC_ERROR]: onLoadStatisticError,
  [actions.CHANGE_DATE]: onChangeDate,
});
