import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import clinicActions from '../../containers/Clinics/actions';
import useModal from '../../helpers/useModal';
import actions from './actions';
import EditClinicModal from './EditClinicModal';
import EditIcon from '../Icons/EditIcon';
import standardLogo from '../../assets/icons/clinics.svg';
import LogOutIcon from '../Icons/LogOutIcon';
import ClinicInfoItemStyleWrapper from './ClinicInfoItem.style';

const ClinicInfoItem = ({ role }) => {
  const { clinic } = useSelector(state => state.ClinicInfo);

  const { name, logo, address } = clinic;

  const dispatch = useDispatch();

  const {
    modalOptions: editClinicModalOptions,
    closeModal: closeEditClinicModal,
    showModal: showEditClinicModal,
  } = useModal({ clinic: {}, edit: true });

  const permissions =
    localStorage.getItem('userPermissions') &&
    localStorage.getItem('userPermissions').split(',');

  useEffect(() => {
    dispatch(actions.loadCurrentClinicRequest());
  }, [dispatch]);

  const slideOutHandler = () => {
    dispatch(clinicActions.slideOutRequest());
  };

  useEffect(() => {
    return () => {
      dispatch(actions.clearState());
    };
  }, [dispatch]);

  return (
    <ClinicInfoItemStyleWrapper>
      <div className="clinic-content">
        <div>
          <img className="clinic-logo" src={logo || standardLogo} alt="" />
        </div>
        <div className="clinic-info">
          <h1 className="clinic-name">{name}</h1>
          <p className="clinic-address">{address}</p>
        </div>
        <div className="btns">
          {role !== 'vet' && (
            <Button
              className="btn-with-icon edit"
              onClick={() => showEditClinicModal({ clinic })}
              icon={<EditIcon />}
            >
              Edit
            </Button>
          )}

          {permissions && (
            <Button
              className="btn-with-icon slide"
              onClick={slideOutHandler}
              icon={<LogOutIcon />}
            >
              Slideout
            </Button>
          )}
        </div>
      </div>
      {editClinicModalOptions.isModalVisible && (
        <EditClinicModal
          modalOptions={editClinicModalOptions}
          close={closeEditClinicModal}
        />
      )}
    </ClinicInfoItemStyleWrapper>
  );
};

export default ClinicInfoItem;
