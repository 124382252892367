import { createReducer } from '../../helpers/reducer';
import actions from './actions';

const initState = {
  clinic: {},
  message: '',
};

function onLoadCurrentClinicSuccess(state, { payload }) {
  return { ...state, clinic: payload };
}

function onLoadInvitationMessageSuccess(state, { payload }) {
  return { ...state, message: payload };
}

function clearMessageState(state) {
  return { ...state, message: initState.message };
}

function clearState() {
  return { ...initState };
}

export default createReducer(initState, {
  [actions.LOAD_CURRENT_CLINIC_SUCCESS]: onLoadCurrentClinicSuccess,
  [actions.LOAD_INVITATION_MESSAGE_SUCCESS]: onLoadInvitationMessageSuccess,
  [actions.CLEAR_MESSAGE_STATE]: clearMessageState,
  [actions.CLEAR_STATE]: clearState
});
