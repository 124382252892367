import styled from 'styled-components';

const SidebarStyleWrapper = styled.div`
  .sidebar {
    box-shadow: 0 3px 14px 0 rgba(22, 63, 145, 0.2);
    height: 100vh;
    max-width: 215px;
    //overflow: auto;
  }

  .ant-menu {
    border: none;
  }

  .ant-menu-item-selected {
    background-color: #003c5b;
    color: #ffffff;
  }
  .ant-menu > .ant-menu-item-active {
    a {
      color: #598ab3;
    }
    color: #598ab3;
    background-color: #eef3f7;
  }

  .ant-menu > .ant-menu-item-selected {
    color: #ffffff;
    background-color: #003c5b;
  }
  .ant-layout-sider-collapsed {
    .logo-icon {
      max-height: 41px;
      transition: max-height linear 0.2s;
    }
    .logo {
      margin: 17px 20px;
      transition: margin linear 0.2s;
    }
    .logo-title {
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.2s,
        color 0.2s;
    }

    .ant-menu-item {
      padding: 2px 10px !important;
    }
  }

  .ant-menu.ant-menu-inline {
    .ant-menu-item {
      width: initial;
    }
    .ant-menu-item-selected:after {
      opacity: 0;
    }
    .ant-menu-submenu-title {
      margin-top: 10px;
      padding: 0 18px !important;
      height: 45px;
      transition: margin-left linear 0.2s;
    }
  }

  .ant-menu.ant-menu-inline-collapsed {
    .ant-menu-submenu-title {
      padding: 0 18px;
      margin-top: 12px;
      margin-left: 5px;
      height: 43px;
      transition: margin-left linear 0.2s;
    }
  }
  .link {
    text-decoration: none;
    color: inherit;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }
  .notice {
    transform: translateY(3px);
    height: 6px;
    width: 6px;
    background-color: red !important;
    border-radius: 50%;
    border: 1px solid white;
    display: inline-block;
    position: relative;
    top: -8px;
    left: -8px;
  }
  .req-users {
    color: #f64744;
    font-size: 10px;
  }

  .ant-menu-item {
    border-radius: 7px;
    font-weight: 550;
    margin: 20px 18px;
    padding: 0 13px !important;
    text-overflow: inherit;
    //padding: 0 calc(50% - 54px / 2) !important;
    color: #003c5b;
  }

  .collapse-trigger {
    display: inline-flex;
  }

  .collapse-trigger-btn {
    margin: 20px 18px;
    border-radius: 7px;
    width: 44px;
    height: 40px;
    background: rgba(89, 138, 179, 0.1) !important;
    border: none !important;
  }
  .collapse-trigger-label {
    font-weight: 550;
    margin: 20px 0;
    line-height: 40px;
    color: #598ab3;

    &:hover {
      cursor: pointer;
    }
  }

  .sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 20px;
    .ant-menu-submenu-arrow {
      display: none;
    }
  }
  .user-name {
    font-weight: 500;
  }
  .avatar-icon-shape {
    background-color: #598ab3;
  }

  @media print {
    display: none;
  }
`;
export default SidebarStyleWrapper;
