import { all, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import messageActions from '../../redux/message/actions';
import clinicActions from '../../containers/Clinics/actions';
import RestApi, { callApi } from '../../helpers/api';

const currentClinicApi = new RestApi('vet/current-clinic');

const api = new RestApi('vet/default-message');

export function* loadCurrentClinic() {
  yield takeEvery(actions.LOAD_CURRENT_CLINIC_REQUEST, function* () {
    const result = yield currentClinicApi.load();
    if (result.status === 200) {
      yield put(actions.loadCurrentClinicSuccess(result.data));
    } else {
      yield put(messageActions.showErrorMessage());
    }
  });
}

export function* editClinic() {
  yield takeEvery(actions.EDIT_CLINIC_DATA, function* ({ payload }) {
    const { dispatch, editData } = payload;

    const role = JSON.parse(localStorage.getItem('auth')).role;

    const result = yield callApi('vet/update-clinic', 'PATCH', editData);
    if (result.status === 200) {
      yield put(actions.editClinicDataSuccess());
      yield put(
        messageActions.showSuccessMessage('Clinic has been edited successfully')
      );
      if (role !== 'super_admin') {
        dispatch(actions.loadCurrentClinicRequest());
      } else {
        yield put(clinicActions.loadClinicListRequest());
      }
    }
  });
}

export function* addClinic() {
  yield takeEvery(actions.ADD_CLINIC_REQUEST, function* ({ payload }) {
    console.log('pay', payload);
    const result = yield callApi(
      'vet/invite-clinic-with-admin',
      'POST',
      payload
    );
    if (result.status === 200) {
      yield put(actions.addClinicSuccess());
      yield put(
        messageActions.showSuccessMessage('Clinic has been added successfully')
      );
      yield put(clinicActions.loadClinicListRequest());
    }
    else{
      yield put(
        messageActions.showErrorMessage(result.data)
      );
    }
  });
}

export function* configureInviteMessage() {
  yield takeEvery(actions.CONFIGURE_INVITE_MESSAGE, function* ({ payload }) {
    const { dispatch, ...params } = payload;
    const result = yield callApi('vet/create-default-message', 'PATCH', params);
    if (result.status === 200) {
      yield put(actions.configureInviteMessageSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Invitation message has been configured successfully'
        )
      );
    }
  });
}

export function* loadInvitationMessage() {
  yield takeEvery(actions.LOAD_INVITATION_MESSAGE, function* ({ payload }) {
    const result = yield api.load(payload);
    if (result.status === 200) {
      yield put(
        actions.loadInvitationMessageSuccess(result.data.defultMessage)
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadCurrentClinic),
    fork(editClinic),
    fork(configureInviteMessage),
    fork(loadInvitationMessage),
    fork(addClinic),
  ]);
}
