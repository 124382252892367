const requestedUsersActions = {
  SHOW_REQUESTED_USERS_REQUEST: 'SHOW_REQUESTED_USERS_REQUEST',
  SHOW_REQUESTED_USERS_SUCCESS: 'SHOW_REQUESTED_USERS_REQUEST_SUCCESS',
  SHOW_REQUESTED_USERS_ERROR: 'SHOW_REQUESTED_USERS_REQUEST_ERROR',
  CHANGE_REQUESTED_USERS_PAGINATION: 'CHANGE_REQUESTED_USERS_PAGINATION',
  ACCEPT_USER_CONNECTION_REQUEST: 'ACCEPT_USER_CONNECTION_REQUEST',
  ACCEPT_USER_CONNECTION_SUCCESS: 'ACCEPT_USER_CONNECTION_SUCCESS',
  ACCEPT_USER_CONNECTION_ERROR: 'ACCEPT_USER_CONNECTION_ERROR',
  REJECT_USER_CONNECTION_REQUEST: 'REJECT_USER_CONNECTION_REQUEST',
  REJECT_USER_CONNECTION_SUCCESS: 'REJECT_USER_CONNECTION_SUCCESS',
  REJECT_USER_CONNECTION_ERROR: 'REJECT_USER_CONNECTION_ERROR',

  showRequestedUsers: data => ({
    type: requestedUsersActions.SHOW_REQUESTED_USERS_REQUEST,
    payload: data,
  }),

  showRequestedUsersSuccess: data => ({
    type: requestedUsersActions.SHOW_REQUESTED_USERS_SUCCESS,
    payload: data,
  }),

  showRequestedUsersError: () => ({
    type: requestedUsersActions.SHOW_REQUESTED_USERS_ERROR,
  }),

  changePagination: data => ({
    type: requestedUsersActions.CHANGE_REQUESTED_USERS_PAGINATION,
    payload: data,
  }),

  acceptUserConnectionRequest: data => ({
    type: requestedUsersActions.ACCEPT_USER_CONNECTION_REQUEST,
    payload: data,
  }),

  acceptUserConnectionSuccess: () => ({
    type: requestedUsersActions.ACCEPT_USER_CONNECTION_SUCCESS,
  }),

  acceptUserConnectionError: () => ({
    type: requestedUsersActions.ACCEPT_USER_CONNECTION_ERROR,
  }),

  rejectUserConnectionRequest: data => ({
    type: requestedUsersActions.REJECT_USER_CONNECTION_REQUEST,
    payload: data,
  }),

  rejectUserConnectionSuccess: () => ({
    type: requestedUsersActions.REJECT_USER_CONNECTION_SUCCESS,
  }),

  rejectUserConnectionError: () => ({
    type: requestedUsersActions.REJECT_USER_CONNECTION_ERROR,
  }),
};

export default requestedUsersActions;
