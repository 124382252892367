export   function setEnv (baseApi, portalUrl) {
  return {
    api: {
      apiUrl: `${baseApi}`,
      portalUrl: `${portalUrl}`
    },
    isProduction: true,
    isDevelopment: false,
  };
}
