import styled from 'styled-components';

const LogoStyleWrapper = styled.div`
  .logo {
    max-width: 190px;
    margin: 25px 15px;
    display: inline-flex;
    transition: margin linear 0.2s;
  }
  .logo-icon {
    max-height: 25px;
    margin-right: 10px;
    font-size: 10px;
    transition: max-height linear 0.2s;
  }

  .logo-title {
    max-height: 40px;
    max-width: 140px;
  }
  `


export default LogoStyleWrapper;
