import { createReducer } from '../../helpers/reducer';
import actions from './actions';

const initialState = {
  tableData: [],
  pagination: {
    limit: 10,
    page: 1,
    total: 0,
  },
  loading: false,
  keyword: '',
  sort: 'Most active',
};

function onLoadVetInfo(state) {
  return {
    ...state,
    loading: true,
  };
}
function onLoadVetInfoSuccess(state, { payload }) {
  return {
    ...state,
    ...payload,
    pagination: { ...state.pagination, ...payload.pagination },
    loading: false,
  };
}
function onLoadVetInfoError(state) {
  return { ...state, loading: false };
}

function onChangePagination(state, { payload }) {
  return { ...state, pagination: payload };
}

function searchVet(state, { payload }) {
  return {
    ...state,
    keyword: payload,
    pagination: { ...initialState.pagination },
  };
}

function onFilterVet(state, { payload }) {
  return { ...state, sort: payload };
}

function onClearState() {
  return { ...initialState };
}

function onInviteDoctorRequest(state) {
  return { ...state, loading: true };
}

function onInviteDoctorSuccess(state) {
  return { ...state, loading: false };
}

function onInviteDoctorError(state) {
  return { ...state, loading: false };
}

function onEditDoctorRequest(state) {
  return { ...state, loading: true };
}

function onEditDoctorSuccess(state) {
  return { ...state, loading: false };
}

function onEditDoctorError(state) {
  return { ...state, loading: false };
}

function onResetPasswordRequest(state) {
  return { ...state, loading: true };
}

function onResetPasswordError(state) {
  return { ...state, loading: false };
}

function onResetPasswordSuccess(state) {
  return { ...state, loading: false };
}

function onReinviteDoctorRequest(state) {
  return { ...state, loading: true };
}

function onReinviteDoctorSuccess(state) {
  return { ...state, loading: false };
}

function onReinviteDoctorError(state) {
  return { ...state, loading: false };
}

export default createReducer(initialState, {
  [actions.LOAD_VET_INFO]: onLoadVetInfo,
  [actions.LOAD_VET_INFO_SUCCESS]: onLoadVetInfoSuccess,
  [actions.LOAD_VET_INFO_ERROR]: onLoadVetInfoError,
  [actions.CHANGE_VET_INFO_TABLE_PAGINATION]: onChangePagination,
  [actions.SEARCH_VET]: searchVet,
  [actions.FILTER_VET]: onFilterVet,
  [actions.CLEAR_STATE]: onClearState,
  [actions.INVITE_DOCTOR_REQUEST]: onInviteDoctorRequest,
  [actions.INVITE_DOCTOR_SUCCESS]: onInviteDoctorSuccess,
  [actions.INVITE_DOCTOR_ERROR]: onInviteDoctorError,
  [actions.EDIT_DOCTOR_REQUEST]: onEditDoctorRequest,
  [actions.EDIT_DOCTOR_SUCCESS]: onEditDoctorSuccess,
  [actions.EDIT_DOCTOR_ERROR]: onEditDoctorError,
  [actions.RESET_PASSWORD_REQUEST]: onResetPasswordRequest,
  [actions.RESET_PASSWORD_SUCCESS]: onResetPasswordSuccess,
  [actions.RESET_PASSWORD_ERROR]: onResetPasswordError,
  [actions.REINVITE_DOCTOR_REQUEST]: onReinviteDoctorRequest,
  [actions.REINVITE_DOCTOR_SUCCESS]: onReinviteDoctorSuccess,
  [actions.REINVITE_DOCTOR_ERROR]: onReinviteDoctorError,
});
