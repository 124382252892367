const ClinicIcon = () => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41075 3.0766L3.63256 6.02064C3.00169 6.51132 2.48999 7.55575 2.48999 8.34781V13.5419C2.48999 15.1682 3.81481 16.5 5.44104 16.5H13.5582C15.1844 16.5 16.5092 15.1682 16.5092 13.5489V8.44594C16.5092 7.59781 15.9415 6.51132 15.2475 6.02765L10.9156 2.99249C9.93421 2.30554 8.35705 2.34059 7.41075 3.0766Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.49976 13.0022V8.79639"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6025 10.8994H7.39673"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClinicIcon;
