import { useState } from 'react';

const useModal = modalOpts => {
  const initialState = { isModalVisible: false, ...modalOpts };

  const [modalOptions, setModalOptions] = useState(initialState);

  const showModal = args => {
    setModalOptions(prevState => ({
      ...prevState,
      isModalVisible: true,
      ...args,
    }));
  };

  const closeModal = () => {
    setModalOptions(initialState);
  };

  return {
    modalOptions,
    closeModal,
    showModal,
  };
};

export default useModal;
