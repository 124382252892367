import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';

import asyncComponent from '../../helpers/AsyncFunc';

const superAdminRoutes = props => {
  return [
    {
      path: '',
      component: asyncComponent(() => import('../homePage'), props),
      permission: ['super_admin', 'admin', 'vet'],
    },
    {
      path: 'blankPage',
      component: asyncComponent(() => import('../blankPage'), props),
      permission: ['super_admin', 'admin', 'vet'],
    },
    {
      path: 'statistics',
      component: asyncComponent(
        () => import('../Statistics/Statistics'),
        props
      ),
      permission: ['super_admin'],
    },
    {
      path: 'clinics',
      component: asyncComponent(() => import('../Clinics/Clinics'), props),
      permission: ['super_admin'],
    },
    {
      path: 'pet-owners',
      component: asyncComponent(() => import('../PetOwners/PetOwners'), props),
      permission: ['super_admin', 'admin', 'vet'],
    },
    {
      path: 'clinics/:id/details',
      component: asyncComponent(
        () => import('../ClinicDetails/ClinicDetails'),
        props
      ),
      permission: ['super_admin'],
    },
    {
      path: 'posts',
      component: asyncComponent(() => import('../Moments/Moments'), props),
      permission: ['super_admin', 'admin', 'vet'],
    },
    {
      path: 'staff-members',
      component: asyncComponent(
        () => import('../StaffMembers/StaffMembers'),
        props
      ),
      permission: ['super_admin', 'admin'],
    },
    {
      path: 'export-pdf',
      component: asyncComponent(
        () => import('../Moments/ExportPdf/ExportPdf'),
        props
      ),
      permission: ['super_admin', 'admin', 'vet'],
    },
  ];
};

const AppRouter = props => {
  const SIGN_IN_ROUTE = '/signin';
  const { accessToken, role } = useSelector(state => state.Auth);
  const isLoggedIn = accessToken !== null;
  const { style } = props;
  const location = useLocation();
  const { search, pathname } = location;
  const { type, ...rest } = qs.parse(search);
  rest.pathname = pathname;
  const url = Object.assign(rest, { type });
  const routeOptions = superAdminRoutes({ role });

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: SIGN_IN_ROUTE,
          search: qs.stringify(url),
        }}
      />
    );
  }

  return (
    <div style={style}>
      {routeOptions
        .filter(({ permission }) => {
          return permission.includes(role);
        })
        .map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact !== false}
              key={path}
              path={`/${path}`}
              {...otherProps}
            />
          );
        })}
    </div>
  );
};

export default AppRouter;
