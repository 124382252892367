import { createReducer } from '../../../helpers/reducer';
import actions from './actions';
import moment from 'moment';

const initialState = {
  chartData: [],
  tableData: [],
  pagination: {
    limit: 10,
    page: 1,
    total: 0,
  },
  year: moment(),
  loading: false,
  interval: 'monthly',
  dailyData: []
};

function onLoadReport(state) {
  return {
    ...state,
    loading: true,
  };
}
function onLoadReportSuccess(state, { payload }) {
  return {
    ...state,
    ...payload,
    pagination: { ...state.pagination, ...payload.pagination },
    loading: false,
  };
}
function onLoadReportError(state) {
  return { ...state, loading: false };
}
function onChangeReportYear(state, { payload }) {
  return { ...state, year: payload };
}
function onChangePagination(state, { payload }) {
  return { ...state, pagination: payload };
}
function onChangeReportInterval(state, { payload }) {
  return { ...state, interval: payload };
}

export default createReducer(initialState, {
  [actions.LOAD_PET_OWNERS_REPORT]: onLoadReport,
  [actions.LOAD_PET_OWNERS_REPORT_SUCCESS]: onLoadReportSuccess,
  [actions.LOAD_PET_OWNERS_REPORT_ERROR]: onLoadReportError,
  [actions.CHANGE_REPORT_YEAR]: onChangeReportYear,
  [actions.CHANGE_REPORT_TABLE_PAGINATION]: onChangePagination,
  [actions.CHANGE_REPORT_INTERVAL]: onChangeReportInterval,
});
