import { createReducer } from '../../helpers/reducer';
import actions from './actions';

const initialState = {
  clinic: {},
  profileImage: '',
  email: '',
  lastLogin: '',
  userId: '',
};

function onLoadProfileSuccess(state, { payload }) {
  const { name, email, image, lastLogin, _id } = payload;
  return {
    ...state,
    name: name || email,
    profileImage: image || '',
    lastLogin: lastLogin || '',
    email: email || '',
    userId: _id || '',
  };
}


export default createReducer(initialState, {
  [actions.LOAD_USER_PROFILE_SUCCESS]: onLoadProfileSuccess,
});
