export function saveAuthData(data) {
  localStorage.setItem('auth', JSON.stringify(data));
}

export function clearAuthData() {
  localStorage.removeItem('auth');
}

export function saveUserPermissionsData(data) {
  localStorage.setItem('userPermissions', JSON.stringify(data));
}

export function clearUserPermissionsData() {
  localStorage.removeItem('userPermissions');
}

export function getAuthData() {
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    return auth;
  } catch (err) {
    clearAuthData();
    return null
  }
}
