import { createReducer } from '../../helpers/reducer';
import actions from './actions';

const initState = {
  message: null,
  isOpen: false,
  isError: false,
};

function showErrorMessage(state, { payload }) {
  return {
    ...state,
    isError: true,
    message: payload || 'Something went wrong',
    isOpen: true,
  };
}

function showSuccessMessage(state, { payload }) {
  return {
    ...state,
    isError: false,
    message: payload,
    isOpen: true,
  };
}

function hideMessage() {
  return {
    ...initState,
  };
}

export default createReducer(initState, {
  [actions.SHOW_SUCCESS_MESSAGE]: showSuccessMessage,
  [actions.SHOW_ERROR_MESSAGE]: showErrorMessage,
  [actions.HIDE_MESSAGE]: hideMessage,
});
