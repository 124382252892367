const COMPONENT = 'FORGOT_PASSWORD';

const forgotPasswordActions = {
  CHANGE_PROPERTY: `${COMPONENT}CHANGE_PROPERTY`,
  TOUCH: `${COMPONENT}TOUCH`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  changeProperty: data => ({
    type: forgotPasswordActions.CHANGE_PROPERTY,
    payload: data,
  }),

  touch: data => ({
    type: forgotPasswordActions.TOUCH,
    payload: data,
  }),

  clearState: () => ({
    type: forgotPasswordActions.CLEAR_STATE,
  }),
  forgotPasswordRequest: data => ({
    type: forgotPasswordActions.FORGOT_PASSWORD_REQUEST,
    payload: data,
  }),
  forgotPasswordSuccess: data => ({
    type: forgotPasswordActions.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  }),

  forgotPasswordError: data => ({
    type: forgotPasswordActions.FORGOT_PASSWORD_ERROR,
    payload: data,
  }),
};
export default forgotPasswordActions;
