import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import messageActions from '../../redux/message/actions';
import RestApi, { callApi } from '../../helpers/api';

const api = new RestApi('vet/vets-info');

const staffMembersState = state => state.StaffMembers;

export function* loadVetsInfo() {
  yield takeEvery(actions.LOAD_VET_INFO, function* ({ payload }) {
    const result = yield api.load(payload);
    if (result.status === 200) {
      const { result: tableData, count } = result.data;
      yield put(
        actions.loadVetInfoSuccess({
          tableData: tableData,
          pagination: { total: count },
        })
      );
    } else {
      const message = result.data;
      yield put(actions.loadVetInfoError(message));
    }
  });
}

export function* inviteDoctor() {
  yield takeEvery(actions.INVITE_DOCTOR_REQUEST, function* ({ payload }) {
    const role = JSON.parse(localStorage.getItem('auth')).role;
    const state = yield select(staffMembersState);
    const { dispatch, ...params } = payload;
    const result = yield callApi('vet/invite-vet', 'POST', params);
    if (result.status === 200) {
      yield put(actions.inviteDoctorSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Your invitation has been sent successfully!'
        )
      );
      if (role !== 'super_admin') {
        dispatch(
          actions.loadVetInfo({
            ...state.pagination,
            sort: state.sort,
            keyword: state.keyword,
            isSuperAdmin: false,
          })
        );
      }
    } else {
      const message = result.data;
      yield put(actions.inviteDoctorError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export function* editDoctor() {
  yield takeEvery(actions.EDIT_DOCTOR_REQUEST, function* ({ payload }) {
    const role = JSON.parse(localStorage.getItem('auth')).role;
    const state = yield select(staffMembersState);
    const { dispatch, editData } = payload;
    const result = yield callApi('vet/edit-vet', 'PATCH', editData);
    if (result.status === 200) {
      yield put(actions.editDoctorSuccess());
      yield put(
        messageActions.showSuccessMessage('Doctor has been edited successfully')
      );
      dispatch(
        actions.loadVetInfo({
          ...state.pagination,
          sort: state.sort,
          keyword: state.keyword,
          isSuperAdmin: role === 'super_admin',
        })
      );
    } else {
      const message = result.data;
      yield put(actions.editDoctorError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export function* resetPassword() {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function* ({ payload }) {
    const role = JSON.parse(localStorage.getItem('auth')).role;
    const state = yield select(staffMembersState);
    const { dispatch, email } = payload;
    const result = yield callApi('vet/reset-password', 'POST', { email });
    if (result.status === 200) {
      yield put(actions.resetPasswordSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Password has been reset successfully!'
        )
      );
      dispatch(
        actions.loadVetInfo({
          ...state.pagination,
          sort: state.sort,
          keyword: state.keyword,
          isSuperAdmin: role === 'super_admin',
        })
      );
    } else {
      const message = result.data;
      yield put(actions.resetPasswordError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export function* reinviteDoctor() {
  yield takeEvery(actions.REINVITE_DOCTOR_REQUEST, function* ({ payload }) {
    const result = yield callApi('vet/reinvite-vet', 'POST', { ...payload });
    if (result.status === 200) {
      yield put(actions.reinviteDoctorSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Your invitation has been successfully resent!'
        )
      );
    } else {
      const message = result.data;
      yield put(actions.reinviteDoctorError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadVetsInfo),
    fork(inviteDoctor),
    fork(editDoctor),
    fork(resetPassword),
    fork(reinviteDoctor),
  ]);
}
