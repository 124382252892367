import styled from 'styled-components';
import theme from './settings/theme';

const { colors, fonts } = theme;

const DashAppHolder = styled.div`
  font-family: ${fonts.primary};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${colors.primary};
      color: ${colors.text};
    }
  }
  .ant-row {
    ${'' /* margin-left: -8px;
    margin-right: -8px; */};
  }

  .ant-row > div {
    padding: 0;
  }
    
  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .title.skeleton,
  .value.skeleton {
    position: relative;
    width: 200px;
    height: 36px;
    border-radius: 5px;
    background-color: ${colors.background};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      animation: shimmer 1.5s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export default DashAppHolder;
