import queryString from 'query-string';
import {envConfig as settings} from '../settings';
import request from './request';
import { getAuthData } from './auth';

function getHeaders() {
  const auth = getAuthData();
  const headers = {
    'Content-Type': 'application/json',
  };

  if (auth) {
    headers.Authorization = `JWT ${auth.accessToken}`;
  }

  return headers;
}

function getPath(pathTemplate, id) {
  const pathParams = pathTemplate.split('/');

  if (id) {
    if (Array.isArray(id)) {
      // eslint-disable-next-line prefer-destructuring
      pathParams[1] = id[0];
      // eslint-disable-next-line prefer-destructuring
      pathParams[3] = id[1];
    } else {
      if (pathParams[2] === ':group_id') {
        pathParams[2] = id;
      } else {
        pathParams[1] = id;
      }

      if (pathParams[3] && pathParams[3].startsWith(':')) {
        return pathParams.slice(0, -1).join('/');
      }
    }

    return pathParams.join('/');
  }
  if (pathParams.length > 1 && pathParams[1].startsWith(':')) {
    return pathParams.slice(0, -1).join('/');
  }

  return pathTemplate;
}

function getApiUrl() {
  const apiSettings = JSON.parse(localStorage.getItem('settings')) || settings;
  return apiSettings.apiUrl;
}

function restApi(path) {
  this.path = path;

  this.load = data => {
    const query = queryString.stringify(data);
    const url = `${settings.apiUrl}/${this.path}${query ? `?${query}` : ''}`;
    return request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
  };

  this.loadDataRequest = ({ search, id, contentTypeHeader, acceptHeader }) => {
    const query = queryString.stringify(search, { arrayFormat: 'bracket' });
    const path = getPath(this.path, id);

    const url = `${getApiUrl()}/${path}?${query}`;

    return request(url, {
      method: 'GET',
      headers: getHeaders(contentTypeHeader, acceptHeader),
    });
  };

  this.loadItem = id => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;

    return request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
  };

  this.loadFormData = ({ id }) => {
    const path = getPath(this.path, id);

    const url = `${getApiUrl()}/${path}/form_data`;

    return request(url, {
      method: 'GET',
      headers: getHeaders(),
    });
  };

  this.createItem = ({ data }) => {
    const url = `${settings.apiUrl}/${path}`;
    return request(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.patchItem = ({ id, data }) => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;
    return request(url, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.updateItem = ({ id, data }) => {
    const url = `${settings.apiUrl}/${this.path}/${id}`;
    return request(url, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  this.updateItemData = ({
    data,
    id,
    search,
    acceptHeader,
    contentTypeHeader,
  }) => {
    const query = queryString.stringify(search);
    const path = getPath(this.path, id);
    const url = `${getApiUrl()}/${path}?${query}`;
    return request(url, {
      method: 'PUT',
      headers: getHeaders(contentTypeHeader, acceptHeader),
      body: JSON.stringify(data),
    });
  };

  this.deleteItem = ({ id, data }) => {
    const path = getPath(this.path, id);
    const url = `${getApiUrl()}/${path}`;
    return request(url, {
      method: 'DELETE',
      headers: getHeaders(),
      body: data ? JSON.stringify(data) : null,
    });
  };

  this.updateItems = ({ data }) => {
    const url = `${settings.apiUrl}/${this.path}`;
    return request(url, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
  };

  return {
    load: this.load,
    loadDataRequest: this.loadDataRequest,
    loadItem: this.loadItem,
    loadFormData: this.loadFormData,
    createItem: this.createItem,
    patchItem: this.patchItem,
    updateItem: this.updateItem,
    updateItemData: this.updateItemData,
    deleteItem: this.deleteItem,
    updateItems: this.updateItems,
  };
}

export function callApi(path, method, data) {
  const url = `${settings.apiUrl}/${path}`;
  return request(url, {
    method,
    headers: getHeaders(),
    data: data,
  });
}

export default restApi;
