import { createReducer } from '../../helpers/reducer';
import actions from './actions';

const initState = {
  tableData: [],
  loading: false,
};

function onLoadClinicListRequest(state) {
  return {
    ...state,
    loading: true,
  };
}
function onLoadClinicListSuccess(state, { payload }) {
  return {
    ...state,
    loading: false,
    ...payload,
  };
}
function onLoadClinicListError(state) {
  return {
    ...state,
    loading: false,
  };
}
function slideInRequest(state) {
  return {
    ...state,
    loading: true,
  };
}
function slideInSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}
function slideInError(state) {
  return {
    ...state,
    loading: false,
  };
}
function slideOutRequest(state) {
  return {
    ...state,
    loading: true,
  };
}
function slideOutSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}
function slideOutError(state) {
  return {
    ...state,
    loading: false,
  };
}

function changeClinicStatusRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function changeClinicStatusSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function changeClinicStatusError(state) {
  return {
    ...state,
    loading: false,
  };
}



export default createReducer(initState, {
  [actions.LOAD_CLINIC_LIST_REQUEST]: onLoadClinicListRequest,
  [actions.LOAD_CLINIC_LIST_SUCCESS]: onLoadClinicListSuccess,
  [actions.LOAD_CLINIC_LIST_ERROR]: onLoadClinicListError,
  [actions.SLIDE_IN_REQUEST]: slideInRequest,
  [actions.SLIDE_IN_SUCCESS]: slideInSuccess,
  [actions.SLIDE_IN_ERROR]: slideInError,
  [actions.SLIDE_OUT_REQUEST]: slideOutRequest,
  [actions.SLIDE_OUT_SUCCESS]: slideOutSuccess,
  [actions.SLIDE_OUT_ERROR]: slideOutError,
  [actions.CHANGE_CLINIC_STATUS_REQUEST]: changeClinicStatusRequest,
  [actions.CHANGE_CLINIC_STATUS_SUCCESS]: changeClinicStatusSuccess,
  [actions.CHANGE_CLINIC_STATUS_ERROR]: changeClinicStatusError,
});
