const clinicsActions = {
  LOAD_CLINIC_LIST_REQUEST: 'LOAD_CLINIC_LIST_REQUEST',
  LOAD_CLINIC_LIST_SUCCESS: 'LOAD_CLINIC_LIST_SUCCESS',
  LOAD_CLINIC_LIST_ERROR: 'LOAD_CLINIC_LIST_ERROR',
  SLIDE_IN_REQUEST: 'SLIDE_IN_REQUEST',
  SLIDE_IN_SUCCESS: 'SLIDE_IN_SUCCESS',
  SLIDE_IN_ERROR: 'SLIDE_IN_ERROR',
  SLIDE_OUT_REQUEST: 'SLIDE_OUT_REQUEST',
  SLIDE_OUT_SUCCESS: 'SLIDE_OUT_SUCCESS',
  SLIDE_OUT_ERROR: 'SLIDE_OUT_ERROR',
  CHANGE_CLINIC_STATUS_REQUEST: 'CHANGE_CLINIC_STATUS_REQUEST',
  CHANGE_CLINIC_STATUS_SUCCESS: 'CHANGE_CLINIC_STATUS_SUCCESS',
  CHANGE_CLINIC_STATUS_ERROR: 'CHANGE_CLINIC_STATUS_ERROR',

  loadClinicListRequest: () => ({
    type: clinicsActions.LOAD_CLINIC_LIST_REQUEST,
  }),
  loadClinicListSuccess: data => ({
    type: clinicsActions.LOAD_CLINIC_LIST_SUCCESS,
    payload: data,
  }),
  loadClinicListError: () => ({
    type: clinicsActions.LOAD_CLINIC_LIST_REQUEST,
  }),
  slideInRequest: data => ({
    type: clinicsActions.SLIDE_IN_REQUEST,
    payload: data,
  }),
  slideInSuccess: data => ({
    type: clinicsActions.SLIDE_IN_SUCCESS,
    payload: data,
  }),
  slideInError: () => ({
    type: clinicsActions.SLIDE_IN_ERROR,
  }),
  slideOutRequest: () => ({
    type: clinicsActions.SLIDE_OUT_REQUEST,
  }),
  slideOutSuccess: () => ({
    type: clinicsActions.SLIDE_OUT_SUCCESS,
  }),
  slideOutError: () => ({
    type: clinicsActions.SLIDE_OUT_ERROR,
  }),
  changeClinicStatusRequest: data => ({
    type: clinicsActions.CHANGE_CLINIC_STATUS_REQUEST,
    payload: data,
  }),
  changeClinicStatusSuccess: () => ({
    type: clinicsActions.CHANGE_CLINIC_STATUS_SUCCESS,
  }),
  changeClinicStatusError: () => ({
    type: clinicsActions.CHANGE_CLINIC_STATUS_ERROR,
  }),
};

export default clinicsActions;
