import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './auth/reducer';
import App from './app/reducer';
import SignIn from '../containers/Signin/reducer';
import ForgotPassword from '../containers/ForgotPassword/reducer';
import PetOwnersReport from '../containers/Statistics/PetOwnersReport/reducer';
import ClinicDetails from '../containers/ClinicDetails/reducer';
import Moments from '../containers/Moments/reducer';
import PetOwners from '../containers/PetOwners/reducer';
import StaffMembers from '../containers/StaffMembers/reducer';
import UserProfile from './profile/reducer';
import MessageReducer from './message/reducer';
import Clinics from '../containers/Clinics/reducer';
import ClinicInfo from '../components/ClinicInfoItem/reducer';
import RequestedUsers from '../containers/PetOwners/Modals/RequestedUsers/reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    Auth,
    App,
    SignIn,
    ForgotPassword,
    PetOwnersReport,
    ClinicDetails,
    Moments,
    PetOwners,
    StaffMembers,
    UserProfile,
    MessageReducer,
    Clinics,
    ClinicInfo,
    RequestedUsers,
  });

export default createRootReducer;
