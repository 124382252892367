import LogoStyleWrapper from './Logo.style';
import logoIcon from '../../assets/images/logo-icon.svg';
import logoTitle from '../../assets/images/logo-title.svg';

const Logo = () => {
  return (
    <LogoStyleWrapper>
      <div className="logo">
        <img className="logo-icon" src={logoIcon} alt="company_logo" />
        <img className="logo-title" src={logoTitle} alt="company_title" />
      </div>
    </LogoStyleWrapper>
  );
};

export default Logo;
