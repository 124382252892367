import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import PublicRoutes from './router';
import { store, history } from './redux/store';
import theme from './settings/theme';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import { IntlProvider } from 'react-intl';

const DashApp = () => (
  <ConfigProvider>
    <ThemeProvider theme={theme.colors}>
      <IntlProvider locale="en" >
        <DashAppHolder>
          <div className="provider">
            <Provider store={store}>
              <PublicRoutes history={history} />
            </Provider>
          </div>
        </DashAppHolder>
      </IntlProvider>
    </ThemeProvider>
  </ConfigProvider>
);

Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
