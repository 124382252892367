import StatisticIcon from '../../components/Icons/StatisticIcon';
import ClinicIcon from '../../components/Icons/ClinicIcon';
import PetIcon from '../../components/Icons/PetIcon';
import MomentIcon from '../../components/Icons/MomentIcon';
import StaffMembersIcon from '../../components/Icons/StaffMembersIcon';

export const roleOptions = [
  {
    key: 'clinics',
    label: 'Clinics',
    icon: <ClinicIcon />,
    permission: ['super_admin']
  },
  {
    key: 'pet-owners',
    label: 'Clients',
    icon: <PetIcon />,
    permission: ['super_admin', 'admin', 'vet']
  },
  // {
  //   key: 'statistics',
  //   label: 'Statistics',
  //   icon: <StatisticIcon />,
  //   permission: ['super_admin']
  // },
  {
    key: 'posts',
    label: 'Posts',
    icon: <MomentIcon />,
    permission: ['super_admin', 'admin', 'vet']
  },
  {
    key: 'staff-members',
    label: 'Staff Members',
    icon: <StaffMembersIcon />,
    permission: ['super_admin','admin']
  },
];
