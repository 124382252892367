import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getAuthData, clearAuthData, saveAuthData, clearUserPermissionsData } from '../../helpers/auth';
import actions from './actions';
import { callApi } from '../../helpers/api';

const signInTypes = {
  GOOGLE: 'GOOGLE',
  EMAIL: 'EMAIL',
};

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* loginRequest({ payload }) {
    const { type, email, password } = payload;
    let result;

    switch (type) {
      case signInTypes.GOOGLE:
        break;
      default:
        result = yield callApi('vet/signin', 'POST', { email, password });
        if (result.status === 200) {
          const { token, role } = result.data;
          saveAuthData({ accessToken: token, role });
          yield put(
            actions.loginSuccess({
              role: role,
              accessToken: token,
            })
          );
        } else {
          const message = result.data;
          yield put(actions.loginError({ error: message }));
        }
        break;
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* logout() {
    clearAuthData();
    clearUserPermissionsData();
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* checkAuthorization() {
    const authData = getAuthData();
    if (authData) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { ...authData },
      });
    }
    else{
       yield put(actions.logout());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(checkAuthorization), fork(loginRequest), fork(logout)]);
}
