const actions = {
  LOAD_PET_OWNERS_REPORT: 'LOAD_PET_OWNERS_REPORT',
  LOAD_PET_OWNERS_REPORT_SUCCESS: 'LOAD_PET_OWNERS_REPORT_SUCCESS',
  LOAD_PET_OWNERS_REPORT_ERROR: 'LOAD_PET_OWNERS_REPORT_ERROR',
  CHANGE_REPORT_YEAR: 'CHANGE_REPORT_YEAR',
  CHANGE_REPORT_TABLE_PAGINATION: 'CHANGE_REPORT_TABLE_PAGINATION',
  CHANGE_REPORT_INTERVAL: 'CHANGE_REPORT_INTERVAL',

  loadReport: (data = {}) => ({
    type: actions.LOAD_PET_OWNERS_REPORT,
    payload: data,
  }),
  loadReportSuccess: data => ({
    type: actions.LOAD_PET_OWNERS_REPORT_SUCCESS,
    payload: data,
  }),
  loadReportError: data => ({
    type: actions.LOAD_PET_OWNERS_REPORT_ERROR,
    payload: data,
  }),
  changeReportYear: data => ({
    type: actions.CHANGE_REPORT_YEAR,
    payload: data,
  }),
  changePagination: data => ({
    type: actions.CHANGE_REPORT_TABLE_PAGINATION,
    payload: data,
  }),
  changeReportInterval: data => ({
    type: actions.CHANGE_REPORT_INTERVAL,
    payload: data,
  }),
};
export default actions;
