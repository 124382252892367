import styled from 'styled-components';

const ClinicInfoItemStyleWrapper = styled.div`
  margin: 13px 13px 0;
  .clinic-content {
    background: #ffffff;
    box-shadow: 0 3px 14px rgba(22, 63, 145, 0.12);
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 0.8fr 6fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
  }
  .clinic-logo {
    max-height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
  .clinic-info {
    overflow: hidden;
    white-space: nowrap;
  }
  .clinic-name {
    font-weight: 500;
    font-size: 16px;
    color: #003c5b;
    margin-bottom: 2px;
  }
  .clinic-address {
    font-weight: 400;
    font-size: 14px;
    color: #003c5b;
    opacity: 0.5;
    margin: 0;
  }
  .btns {
    display: flex;
    gap: 5px;
  }
  .btn-with-icon {
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px 25px;
    &.edit {
      background: rgba(89, 138, 179, 0.1);
      color: #598ab3;
    }
    &.slide {
      color: #ffffff;
      background: #003c5b;
    }
  }
  
  
  @media print {
    display: none;
  }
  
  
`;

export default ClinicInfoItemStyleWrapper;
