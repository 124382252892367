const PetIcon = () => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49172 13.7638C3.30172 16.0517 5.02755 17.92 7.32338 17.92H11.1154C13.6963 17.92 15.4696 15.838 15.0421 13.2888C14.5909 10.613 12.01 8.42004 9.2946 8.42004C6.34963 8.42004 3.73713 10.8267 3.49172 13.7638Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.49918 5.63839C10.5922 5.63839 11.4783 4.75228 11.4783 3.65922C11.4783 2.56616 10.5922 1.68005 9.49918 1.68005C8.40611 1.68005 7.52001 2.56616 7.52001 3.65922C7.52001 4.75228 8.40611 5.63839 9.49918 5.63839Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1533 8.16659C17.0277 8.16659 17.7367 7.4577 17.7367 6.58325C17.7367 5.7088 17.0277 4.99992 16.1533 4.99992C15.2789 4.99992 14.57 5.7088 14.57 6.58325C14.57 7.4577 15.2789 8.16659 16.1533 8.16659Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.84334 8.16667C3.71779 8.16667 4.42668 7.45778 4.42668 6.58333C4.42668 5.70888 3.71779 5 2.84334 5C1.96889 5 1.26001 5.70888 1.26001 6.58333C1.26001 7.45778 1.96889 8.16667 2.84334 8.16667Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PetIcon;
