import { takeEvery, put, fork } from 'redux-saga/effects';

import actions from './actions';
import authActions from '../../redux/auth/actions';
import messageActions from '../../redux/message/actions';
import RestApi, { callApi } from '../../helpers/api';
import {
  clearUserPermissionsData,
  saveAuthData,
  saveUserPermissionsData,
} from '../../helpers/auth';
import { push } from 'connected-react-router';

const api = new RestApi('vet/clinics');

export function* loadClinicList() {
  yield takeEvery(actions.LOAD_CLINIC_LIST_REQUEST, function* () {
    const result = yield api.load();
    if (result.status === 200) {
      const { data: tableData } = result;
      yield put(
        actions.loadClinicListSuccess({
          tableData,
        })
      );
    } else {
      yield put(actions.loadClinicListError());
      yield put(messageActions.showErrorMessage());
    }
  });
}

export function* slideIn() {
  yield takeEvery(actions.SLIDE_IN_REQUEST, function* ({ payload }) {
    const result = yield callApi('vet/slidein', 'POST', payload);
    if (result.status === 200) {
      const { token, role, permissions } = result.data;
      saveAuthData({ accessToken: token, role });
      saveUserPermissionsData(permissions);
      yield put(
        authActions.loginSuccess({
          role: role,
          accessToken: token,
        })
      );
      yield put(push('/posts'));
    } else {
      yield put(actions.slideInError());
      yield put(messageActions.showErrorMessage());
    }
  });
}

export function* slideOut() {
  yield takeEvery(actions.SLIDE_OUT_REQUEST, function* () {
    const result = yield callApi('vet/slideout', 'POST');
    if (result.status === 200) {
      const { token, role } = result.data;
      saveAuthData({ accessToken: token, role });
      yield put(actions.slideOutSuccess());
      yield put(
        authActions.loginSuccess({
          role: role,
          accessToken: token,
        })
      );
      clearUserPermissionsData();
    } else {
      yield put(actions.slideOutError());
      yield put(messageActions.showErrorMessage());
    }
  });
}

export function* changeClinicStatus() {
  yield takeEvery(
    actions.CHANGE_CLINIC_STATUS_REQUEST,
    function* ({ payload }) {
      const result = yield callApi('vet/change-clinic-status', 'POST', payload);
      if (result.status === 200) {
        yield put(
          messageActions.showSuccessMessage(
            'Clinic status has been changed successfully'
          )
        );
        yield put(actions.changeClinicStatusSuccess());
        yield put(actions.loadClinicListRequest());
      } else {
        yield put(actions.changeClinicStatusError());
        yield put(messageActions.showErrorMessage());
      }
    }
  );
}

export default function* rootSaga() {
  yield fork(loadClinicList);
  yield fork(slideIn);
  yield fork(slideOut);
  yield fork(changeClinicStatus);
}
