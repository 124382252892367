const momentsInfoActions = {
  LOAD_MOMENTS_INFO: 'LOAD_MOMENTS_INFO',
  LOAD_MOMENTS_INFO_SUCCESS: 'LOAD_MOMENTS_INFO_SUCCESS',
  LOAD_MOMENTS_INFO_ERROR: 'LOAD_MOMENTS_INFO_ERROR',
  CHANGE_MOMENTS_INFO_TABLE_PAGINATION: 'CHANGE_MOMENTS_INFO_TABLE_PAGINATION',
  FILTER_MOMENTS_INFO_TABLE: 'FILTER_MOMENTS_INFO_TABLE',
  GET_USERS_LIST: 'GET_USERS_LIST',
  GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
  CLEAR_STATE: 'CLEAR_STATE',
  CLEAR_FILTER_STATE: 'CLEAR_FILTER_STATE',
  SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',
  MARK_MOMENTS_REQUEST: 'MARK_MOMENTS_REQUEST',
  MARK_MOMENTS_SUCCESS: 'MARK_MOMENTS_SUCCESS',
  MARK_MOMENTS_ERROR: 'MARK_MOMENTS_ERROR',
  SORT_MOMENTS: 'SORT_MOMENTS',
  CHANGE_TYPE_REQUEST: "CHANGE_TYPE_REQUEST",
  CHANGE_TYPE_SUCCESS: "CHANGE_TYPE_SUCCESS",
  GET_MOMENT_MESSAGES: 'GET_MOMENT_MESSAGES',
  GET_MOMENT_MESSAGES_SUCCESS: 'GET_MOMENT_MESSAGES_SUCCESS',

  loadMomentsInfo: data => ({
    type: momentsInfoActions.LOAD_MOMENTS_INFO,
    payload: data,
  }),
  loadMomentsInfoSuccess: data => ({
    type: momentsInfoActions.LOAD_MOMENTS_INFO_SUCCESS,
    payload: data,
  }),

  loadMomentsInfoError: data => ({
    type: momentsInfoActions.LOAD_MOMENTS_INFO_ERROR,
    payload: data,
  }),

  changePagination: data => ({
    type: momentsInfoActions.CHANGE_MOMENTS_INFO_TABLE_PAGINATION,
    payload: data,
  }),

  filterMoments: data => ({
    type: momentsInfoActions.FILTER_MOMENTS_INFO_TABLE,
    payload: data,
  }),

  getUsersList: data => ({
    type: momentsInfoActions.GET_USERS_LIST,
    payload: data,
  }),

  getUsersListSuccess: data => ({
    type: momentsInfoActions.GET_USERS_LIST_SUCCESS,
    payload: data,
  }),

  clearState: () => ({
    type: momentsInfoActions.CLEAR_STATE,
  }),

  clearFilterState: () => ({
    type: momentsInfoActions.CLEAR_FILTER_STATE,
  }),

  sendMessageRequest: data => ({
    type: momentsInfoActions.SEND_MESSAGE_REQUEST,
    payload: data,
  }),

  sendMessageSuccess: () => ({
    type: momentsInfoActions.SEND_MESSAGE_SUCCESS,
  }),

  sendMessageError: () => ({
    type: momentsInfoActions.SEND_MESSAGE_ERROR,
  }),

  markMomentsRequest: data => ({
    type: momentsInfoActions.MARK_MOMENTS_REQUEST,
    payload: data,
  }),

  markMomentsError: data => ({
    type: momentsInfoActions.MARK_MOMENTS_ERROR,
    payload: data,
  }),

  markMomentsSuccess: data => ({
    type: momentsInfoActions.MARK_MOMENTS_SUCCESS,
    payload: data,
  }),

  sortMoments: data => ({
    type: momentsInfoActions.SORT_MOMENTS,
    payload: data
  }),

  changeTypeRequest: data => ({
    type: momentsInfoActions.CHANGE_TYPE_REQUEST,
    payload: data
  }),
  changeTypeSuccess: () => ({
    type: momentsInfoActions.CHANGE_TYPE_SUCCESS,
  }),

  getMomentMessage: data => ({
    type: momentsInfoActions.GET_MOMENT_MESSAGES,
    payload: data
  }),

  getMomentMessageSuccess: data => ({
    type: momentsInfoActions.GET_MOMENT_MESSAGES_SUCCESS,
    payload: data
  }),
};
export default momentsInfoActions;
