import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Input, Form } from 'antd';

import actions from '../../components/ClinicInfoItem/actions';
import badgeIos from '../../assets/images/badge-ios.svg';
import badgeAndroid from '../../assets/images/badge-android.svg';
import GlobalStyle from '../../GlobalStyle';

const { TextArea } = Input;

const PreviewInviteEmailModal = ({ modalOptions, closeModal }) => {
  const dispatch = useDispatch();

  const { message } = useSelector(state => state.ClinicInfo);

  const [form] = Form.useForm();

  const { clinicId, clinicName } = modalOptions.clinicInfo;

  useEffect(() => {
    form.resetFields();
  }, [message, form]);

  useEffect(() => {
    if (!modalOptions.preview) {
      dispatch(actions.loadInvitationMessage({ clinicId }));
    }
  }, [dispatch, modalOptions.preview, clinicId]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearMessageState());
    };
  }, [dispatch]);

  const configureInviteHandler = value => {
    dispatch(
      actions.configureInviteMessage({
        dispatch,
        ...value,
        clinicId,
      })
    );
    closeModal();
  };

  return (
    <>
      <GlobalStyle />
      <Modal
        destroyOnClose={true}
        width={700}
        className="custom-modal"
        title={
          modalOptions.preview ? 'Preview Invite Email' : 'Invite Configuration'
        }
        visible={modalOptions.isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button
            key="close"
            className="cancel"
            type="primary"
            onClick={closeModal}
          >
            CLOSE
          </Button>,
          !modalOptions.preview && (
            <Button
              key="save"
              form="configure-invite-form"
              htmlType="submit"
              type="primary"
              className="confirm"
            >
              SAVE
            </Button>
          ),
        ]}
      >
        <h1 className="title">
          {clinicName} invited you to use the MyPetChart application to share
          posts about your animal's health
        </h1>
        <Form
          form={form}
          id="configure-invite-form"
          onFinish={configureInviteHandler}
        >
          <Form.Item
            name="defaultMessage"
            rules={[
              {
                max: 350,
                message: 'Value should be less than 350 character',
              },
            ]}
            initialValue={message}
          >
            {modalOptions.preview ? (
              <p className="message-text">{modalOptions.message}</p>
            ) : (
              <TextArea rows={6} />
            )}
          </Form.Item>
        </Form>
        <div className="footer">
          <p className="footer-text">
            Please open Apple Store (iOS) or Google Play (Android) on your
            mobile device and download and install the MyPetChart application
          </p>
          <div className="store-icons">
            <a
              target="_blank"
              href="https://apps.apple.com/ua/app/mypetchart/id1660614885"
            >
              <img src={badgeIos} alt="App Store Icon" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=vet.mypetchart"
            >
              <img src={badgeAndroid} alt="Play Market Icon" />
            </a>
          </div>
          <p>
            To sign-in MyPetChart , please use
            <b> email</b> as your login
          </p>
        </div>
      </Modal>
    </>
  );
};

export default PreviewInviteEmailModal;
