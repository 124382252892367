const COMPONENT = 'CLINIC_INFO_';

const clinicInfoActions = {
  LOAD_CURRENT_CLINIC_REQUEST: `${COMPONENT}LOAD_CURRENT_CLINIC_REQUEST`,
  LOAD_CURRENT_CLINIC_SUCCESS: `${COMPONENT}LOAD_CURRENT_CLINIC_SUCCESS`,
  LOAD_CURRENT_CLINIC_ERROR: `${COMPONENT}LOAD_CURRENT_CLINIC_ERROR`,
  EDIT_CLINIC_DATA: `${COMPONENT}EDIT_CLINIC_DATA`,
  EDIT_CLINIC_DATA_SUCCESS: `${COMPONENT}EDIT_CLINIC_DATA_SUCCESS`,
  EDIT_CLINIC_DATA_FAIL: `${COMPONENT}EDIT_CLINIC_DATA_FAIL`,
  ADD_CLINIC_REQUEST: `${COMPONENT}EDIT_CLINIC_DATA_REQUEST`,
  ADD_CLINIC_SUCCESS: `${COMPONENT}EDIT_CLINIC_SUCCESS`,
  ADD_CLINIC_ERROR: `${COMPONENT}EDIT_CLINIC_ERROR`,
  CONFIGURE_INVITE_MESSAGE: `${COMPONENT}CONFIGURE_INVITE_MESSAGE`,
  CONFIGURE_INVITE_MESSAGE_SUCCESS: `${COMPONENT}CONFIGURE_INVITE_MESSAGE_SUCCESS`,
  CONFIGURE_INVITE_MESSAGE_ERROR: `${COMPONENT}CONFIGURE_INVITE_MESSAGE_ERROR`,
  LOAD_INVITATION_MESSAGE: `${COMPONENT}LOAD_INVITATION_MESSAGE`,
  LOAD_INVITATION_MESSAGE_SUCCESS: `${COMPONENT}LOAD_INVITATION_MESSAGE_SUCCESS`,
  LOAD_INVITATION_MESSAGE_ERROR: `${COMPONENT}LOAD_INVITATION_MESSAGE_ERROR`,
  CLEAR_MESSAGE_STATE: `${COMPONENT}CLEAR_MESSAGE_STATE`,
  CLEAR_STATE: `${COMPONENT}CLEAR_STATE`,

  loadCurrentClinicRequest: () => ({
    type: clinicInfoActions.LOAD_CURRENT_CLINIC_REQUEST,
  }),
  loadCurrentClinicSuccess: data => ({
    type: clinicInfoActions.LOAD_CURRENT_CLINIC_SUCCESS,
    payload: data,
  }),
  loadCurrentClinicError: () => ({
    type: clinicInfoActions.LOAD_CURRENT_CLINIC_ERROR,
  }),
  editClinicData: data => ({
    type: clinicInfoActions.EDIT_CLINIC_DATA,
    payload: data,
  }),
  editClinicDataSuccess: () => ({
    type: clinicInfoActions.EDIT_CLINIC_DATA_SUCCESS,
  }),
  editClinicDataFail: () => ({
    type: clinicInfoActions.EDIT_CLINIC_DATA_FAIL,
  }),
  addClinicRequest: data => ({
    type: clinicInfoActions.ADD_CLINIC_REQUEST,
    payload: data,
  }),
  addClinicSuccess: () => ({
    type: clinicInfoActions.ADD_CLINIC_SUCCESS,
  }),
  addClinicError: () => ({
    type: clinicInfoActions.ADD_CLINIC_ERROR,
  }),
  configureInviteMessage: data => ({
    type: clinicInfoActions.CONFIGURE_INVITE_MESSAGE,
    payload: data,
  }),
  configureInviteMessageSuccess: () => ({
    type: clinicInfoActions.CONFIGURE_INVITE_MESSAGE_SUCCESS,
  }),
  configureInviteMessageError: () => ({
    type: clinicInfoActions.CONFIGURE_INVITE_MESSAGE_ERROR,
  }),
  loadInvitationMessage: data => ({
    type: clinicInfoActions.LOAD_INVITATION_MESSAGE,
    payload: data,
  }),
  loadInvitationMessageSuccess: data => ({
    type: clinicInfoActions.LOAD_INVITATION_MESSAGE_SUCCESS,
    payload: data,
  }),
  loadInvitationMessageError: () => ({
    type: clinicInfoActions.LOAD_INVITATION_MESSAGE_ERROR,
  }),
  clearMessageState: () => ({
    type: clinicInfoActions.CLEAR_MESSAGE_STATE,
  }),
  clearState: () => ({
    type: clinicInfoActions.CLEAR_STATE,
  }),
};

export default clinicInfoActions;
