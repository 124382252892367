import environment from 'environment'

const envConfig = {
  apiUrl: environment.api.apiUrl,
  portalUrlOld: environment.api.portalUrl,
};


const siteConfig = {
  siteName: 'mypetchart',
  siteIcon: 'ion-flash',
  footerText: '©2022 All rights reserved. MyPetChart.',
};

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};



const signInTypes = {
  GOOGLE: 'GOOGLE',
  EMAIL: 'EMAIL',
};

const format = {
  date: 'MMM DD, YYYY',
  dateTime: 'MMM DD, YYYY, (hh:mm A z)',
  time: 'hh:mm A z',
};


export {
  siteConfig,
  jwtConfig,
  signInTypes,
  format,
  envConfig
};
