import { takeEvery, put, fork, select } from 'redux-saga/effects';

import actions from './actions';
import messageActions from '../../redux/message/actions';
import RestApi, { callApi } from '../../helpers/api';

const api = new RestApi('vet/moments');
const usersApi = new RestApi('vet/clients-list');
const messagesApi = new RestApi('vet/message');

const momentsState = state => state.Moments;

export function* loadUsersList() {
  yield takeEvery(actions.GET_USERS_LIST, function* ({ payload }) {
    const result = yield usersApi.load(payload);
    if (result.status === 200) {
      const users = result.data.map(user => {
        return {
          userId: user._id,
          userName: user.name,
          userEmail: user.email,
          pets: user.pets,
        };
      });
      yield put(
        actions.getUsersListSuccess({
          users: users,
        })
      );
    } else {
      const message = result.data;
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export function* loadMomentsInfo() {
  yield takeEvery(actions.LOAD_MOMENTS_INFO, function* ({ payload }) {
    payload.offset = new Date().getTimezoneOffset();
    const result = yield api.load(payload);
    if (result.status === 200) {
      const { momentsData: momentsList, count, full, total } = result.data;
      yield put(
        actions.loadMomentsInfoSuccess({
          momentsList,
          pagination: { total: count },
          full,
          total,
        })
      );
    } else {
      const message = result.data;
      yield put(actions.loadMomentsInfoError(message));
    }
  });
}

export function* getMomentMessage() {
  yield takeEvery(actions.GET_MOMENT_MESSAGES, function* ({ payload }) {
    const result = yield messagesApi.load(payload);
    if (result.status === 200) {
      const { message: messagesList } = result.data;
      yield put(
        actions.getMomentMessageSuccess({
          messagesList: messagesList,
        })
      );
    } else {
      // const message = result.data;
      // yield put(actions.loadMomentsInfoError(message));
    }
  });
}

export function* sendMessage() {
  yield takeEvery(actions.SEND_MESSAGE_REQUEST, function* ({ payload }) {
    const { isSuperAdmin, dispatch, ...params } = payload;

    const state = yield select(momentsState);

    const result = yield callApi('vet/message', 'POST', params);
    if (result.status === 200) {
      yield put(actions.sendMessageSuccess());
      yield put(
        messageActions.showSuccessMessage('Message was sent successfully!')
      );

        dispatch(
          actions.loadMomentsInfo({
            ...state.pagination,
            isSuperAdmin,
            ...state.filter,
            type: state.type,
            sort: state.sort,
          })
        );
    } else {
      const message = result.data;
      yield put(actions.loadMomentsInfoError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export function* markMoments() {
  yield takeEvery(actions.MARK_MOMENTS_REQUEST, function* ({ payload }) {
    const { isSuperAdmin, dispatch, ...params } = payload;
    const result = yield callApi('vet/mark-moments', 'POST', params);
    const state = yield select(momentsState);
    if (result.status === 200) {
      yield put(actions.markMomentsSuccess());
      dispatch(
        actions.loadMomentsInfo({
          ...state.pagination,
          isSuperAdmin,
          ...state.filter,
          type: state.type,
          sort: state.sort,
        })
      );
      yield put(
        messageActions.showSuccessMessage(
          `${
            state.type === 'moment' ? 'Post(s)' : 'Message(s)'
          } have been marked as read successfully!`
        )
      );
    } else {
      const message = result.data;
      yield put(actions.loadMomentsInfoError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export default function* rootSaga() {
  yield fork(loadMomentsInfo);
  yield fork(loadUsersList);
  yield fork(sendMessage);
  yield fork(markMoments);
  yield fork(getMomentMessage);
}
