const StaffMembersIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.30466 1.76547L4.35428 3.24588C3.44387 3.58629 2.69971 4.66295 2.69971 5.6367V11.5188C2.69971 12.4529 3.31722 13.68 4.0693 14.2421L7.47346 16.7834C8.58974 17.6226 10.4263 17.6226 11.5426 16.7834L14.9468 14.2421C15.6989 13.68 16.3163 12.4529 16.3163 11.5188V5.6367C16.3163 4.66295 15.5722 3.58629 14.6617 3.24588L10.7114 1.76547C10.0385 1.52005 8.96174 1.52005 8.30466 1.76547Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1901 9.65833H7.83008"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.51001 8V11.37"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StaffMembersIcon;
