import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import forgotPasswordSaga from '../containers/ForgotPassword/saga';
import petOwnersReportSaga from '../containers/Statistics/PetOwnersReport/saga';
import userProfileSaga from '../redux/profile/saga';
import clinicDetailsSaga from '../containers/ClinicDetails/saga';
import momentsSaga from '../containers/Moments/saga';
import petOwnersSaga from '../containers/PetOwners/saga';
import staffMembersSaga from '../containers/StaffMembers/saga';
import notificationByClientSaga from '../containers/PetOwners/Modals/saga';
import clinicsSaga from '../containers/Clinics/saga';
import clinicInfoSaga from '../components/ClinicInfoItem/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    forgotPasswordSaga(),
    petOwnersReportSaga(),
    userProfileSaga(),
    clinicDetailsSaga(),
    momentsSaga(),
    petOwnersSaga(),
    staffMembersSaga(),
    notificationByClientSaga(),
    clinicsSaga(),
    clinicInfoSaga()
  ]);
}
