import { createReducer } from '../../helpers/reducer';
import actions from './actions';
import modalActions from './Modals/actions';

const initialState = {
  tableData: [],
  pagination: {
    limit: 10,
    page: 1,
    total: 0,
  },
  keyword: '',
  loading: false,
  sort: 'Most active',
  petsSubscription: [],
  horseSubscription: [],
  newestMomentDate: '',
};

function onLoadClientInfo(state) {
  return {
    ...state,
    loading: true,
  };
}
function onLoadClientInfoSuccess(state, { payload }) {
  return {
    ...state,
    ...payload,
    pagination: { ...state.pagination, ...payload.pagination },
    loading: false,
  };
}
function onLoadClientInfoError(state) {
  return { ...state, loading: false };
}

function onChangePagination(state, { payload }) {
  return { ...state, pagination: payload };
}

function onSearchClient(state, { payload }) {
  return {
    ...state,
    keyword: payload,
    pagination: { ...initialState.pagination },
  };
}

function onFilterClient(state, { payload }) {
  return { ...state, sort: payload };
}

function onClearState() {
  return { ...initialState };
}

function onLoadNotificationByClient(state) {
  return {
    ...state,
    loading: true,
  };
}

function onLoadNotificationByClientSuccess(state, { payload }) {
  return {
    ...state,
    ...payload,
    loading: false,
  };
}

function onLoadNotificationByClientError(state) {
  return { ...state, loading: false };
}

function onRemoveClientRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onRemoveClientSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function onRemoveClientError(state) {
  return {
    ...state,
    loading: false,
  };
}

function onInviteClientRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onInviteClientSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function onInviteClientError(state) {
  return {
    ...state,
    loading: false,
  };
}

function onUnlinkClientRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onUnlinkClientSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function onUnlinkClientError(state) {
  return {
    ...state,
    loading: false,
  };
}

function onSendEmailRequest(state) {
  return {
    ...state,
    loading: true,
  };
}

function onSendEmailSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function onSendEmailError(state) {
  return {
    ...state,
    loading: false,
  };
}

export default createReducer(initialState, {
  [actions.LOAD_CLIENT_INFO]: onLoadClientInfo,
  [actions.LOAD_CLIENT_INFO_SUCCESS]: onLoadClientInfoSuccess,
  [actions.LOAD_CLIENT_INFO_ERROR]: onLoadClientInfoError,
  [actions.CHANGE_CLIENT_INFO_TABLE_PAGINATION]: onChangePagination,
  [actions.SEARCH_CLIENT]: onSearchClient,
  [actions.FILTER_CLIENT]: onFilterClient,
  [actions.CLEAR_STATE]: onClearState,
  [modalActions.LOAD_NOTIFICATION_BY_CLIENT]: onLoadNotificationByClient,
  [modalActions.LOAD_NOTIFICATION_BY_CLIENT_SUCCESS]:
    onLoadNotificationByClientSuccess,
  [modalActions.LOAD_NOTIFICATION_BY_CLIENT_ERROR]:
    onLoadNotificationByClientError,
  [modalActions.REMOVE_CLIENT_REQUEST]: onRemoveClientRequest,
  [modalActions.REMOVE_CLIENT_SUCCESS]: onRemoveClientSuccess,
  [modalActions.REMOVE_CLIENT_ERROR]: onRemoveClientError,
  [modalActions.INVITE_CLIENT_REQUEST]: onInviteClientRequest,
  [modalActions.INVITE_CLIENT_SUCCESS]: onInviteClientSuccess,
  [modalActions.INVITE_CLIENT_ERROR]: onInviteClientError,
  [modalActions.UNLINK_CLIENT_REQUEST]: onUnlinkClientRequest,
  [modalActions.UNLINK_CLIENT_SUCCESS]: onUnlinkClientSuccess,
  [modalActions.UNLINK_CLIENT_ERROR]: onUnlinkClientError,
  [modalActions.SEND_EMAIL_REQUEST]: onSendEmailRequest,
  [modalActions.SEND_EMAIL_SUCCESS]: onSendEmailSuccess,
  [modalActions.SEND_EMAIL_ERROR]: onSendEmailError,
});
