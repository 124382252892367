import { all, takeEvery, put, fork, select } from 'redux-saga/effects';

import actions from './actions';
import petOwnersActions from '../actions';
import messageActions from '../../../redux/message/actions';
import requestedUsersActions from './RequestedUsers/actions';
import clinicInfoActions from '../../../components/ClinicInfoItem/actions';
import RestApi, { callApi } from '../../../helpers/api';

const api = new RestApi('vet/get-notifications-details-list-by-user');

const requestUserApi = new RestApi('vet/requested-users-list');

const petOwnersState = state => state.PetOwners;

const reqUsersState = state => state.RequestedUsers;

export function* removeClientRequest() {
  yield takeEvery(actions.REMOVE_CLIENT_REQUEST, function* ({ payload }) {
    const state = yield select(petOwnersState);

    const { isSuperAdmin, dispatch, ...params } = payload;

    const result = yield callApi('vet/remove-client', 'POST', params);
    if (result.status === 200) {
      yield put(actions.removeClientSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Client has been removed successfully'
        )
      );
      dispatch(
        petOwnersActions.loadClientInfo({
          ...state.pagination,
          sort: state.sort,
          keyword: state.keyword,
          isSuperAdmin,
        })
      );
    } else {
      const message = result.data;
      yield put(actions.removeClientError({ status: message }));
    }
  });
}

export function* unlinkClientRequest() {
  yield takeEvery(actions.UNLINK_CLIENT_REQUEST, function* ({ payload }) {
    const state = yield select(petOwnersState);

    const { isSuperAdmin, dispatch, ...params } = payload;

    const result = yield callApi('vet/unlink-client', 'POST', params);

    if (result.status === 200) {
      yield put(actions.unlinkClientSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Client has been unlinked successfully'
        )
      );
      dispatch(
        petOwnersActions.loadClientInfo({
          ...state.pagination,
          sort: state.sort,
          keyword: state.keyword,
          isSuperAdmin,
        })
      );
    } else {
      const message = result.data;
      yield put(actions.removeClientError({ status: message }));
    }
  });
}

export function* loadNotificationByClient() {
  yield takeEvery(actions.LOAD_NOTIFICATION_BY_CLIENT, function* ({ payload }) {
    const result = yield api.load(payload);
    if (result.status === 200) {
      const data = result.data;
      yield put(
        actions.loadNotificationByClientSuccess({
          newestMomentDate: data.newestMomentDate,
        })
      );
    } else {
      const message = result.data;
      yield put(actions.loadNotificationByClientError(message));
    }
  });
}

export function* inviteUser() {
  yield takeEvery(actions.INVITE_CLIENT_REQUEST, function* ({ payload }) {
    const state = yield select(petOwnersState);
    const { isSuperAdmin, dispatch, reinvite, ...params } = payload;
    let result;
    if (reinvite) {
      result = yield callApi('vet/reinvite-user', 'POST', params);
    } else {
      result = yield callApi('vet/invite-user', 'POST', params);
    }
    if (result.status === 200) {
      yield put(actions.inviteClientSuccess());
      yield put(
        messageActions.showSuccessMessage(
          'Client has been invited successfully'
        )
      );
      dispatch(
        petOwnersActions.loadClientInfo({
          ...state.pagination,
          sort: state.sort,
          keyword: state.keyword,
          isSuperAdmin,
        })
      );
    } else {
      const message = result.data;
      yield put(messageActions.showErrorMessage(message));
      yield put(actions.inviteClientError());
    }
  });
}

export function* showRequestedUsers() {
  yield takeEvery(
    requestedUsersActions.SHOW_REQUESTED_USERS_REQUEST,
    function* ({ payload }) {
      const result = yield requestUserApi.load(payload);
      if (result.status === 200) {
        const { result: tableData, total } = result.data;
        yield put(
          requestedUsersActions.showRequestedUsersSuccess({
            tableData,
            pagination: { total },
          })
        );
      } else {
        const message = result.data;
        yield put(requestedUsersActions.showRequestedUsersError());
        yield put(messageActions.showSuccessMessage(message));
      }
    }
  );
}

export function* acceptUserConnection() {
  yield takeEvery(
    requestedUsersActions.ACCEPT_USER_CONNECTION_REQUEST,
    function* ({ payload }) {
      const { dispatch, isSuperAdmin, ...params } = payload;
      const requestedUsersState = yield select(reqUsersState);
      const clientsState = yield select(petOwnersState);
      const result = yield callApi('vet/approve-connection', 'POST', params);
      if (result.status === 200) {
        yield put(requestedUsersActions.acceptUserConnectionSuccess());
        dispatch(
          requestedUsersActions.showRequestedUsers({
            ...requestedUsersState.pagination,
          })
        );

        dispatch(
          petOwnersActions.loadClientInfo({
            ...clientsState.pagination,
            sort: clientsState.sort,
            keyword: clientsState.keyword,
            isSuperAdmin,
          })
        );
        dispatch(clinicInfoActions.loadCurrentClinicRequest());
        yield put(
          messageActions.showSuccessMessage(
            'User has been connected successfully'
          )
        );
      } else {
        const message = result.data;
        yield put(requestedUsersActions.acceptUserConnectionError());
        yield put(messageActions.showErrorMessage(message));
      }
    }
  );
}

export function* rejectUserConnection() {
  yield takeEvery(
    requestedUsersActions.REJECT_USER_CONNECTION_REQUEST,
    function* ({ payload }) {
      const { dispatch, ...params } = payload;
      const requestedUsersState = yield select(reqUsersState);
      const result = yield callApi('vet/reject-connection', 'POST', params);
      if (result.status === 200) {
        yield put(requestedUsersActions.rejectUserConnectionSuccess());
        dispatch(
          requestedUsersActions.showRequestedUsers({
            ...requestedUsersState.pagination,
          })
        );
        dispatch(clinicInfoActions.loadCurrentClinicRequest());
        yield put(
          messageActions.showSuccessMessage(
            'User has been rejected successfully'
          )
        );
      } else {
        const message = result.data;
        yield put(requestedUsersActions.rejectUserConnectionError());
        yield put(messageActions.showErrorMessage(message));
      }
    }
  );
}

export function* sendEmail() {
  yield takeEvery(actions.SEND_EMAIL_REQUEST, function* ({ payload }) {
    const result = yield callApi('vet/send-email', 'POST', payload);
    if (result.status === 200) {
      yield put(actions.sendEmailSuccess());
      yield put(
        messageActions.showSuccessMessage('Email was sent successfully!')
      );
    } else {
      const message = result.data;
      yield put(actions.sendEmailError());
      yield put(messageActions.showErrorMessage(message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadNotificationByClient),
    fork(removeClientRequest),
    fork(inviteUser),
    fork(unlinkClientRequest),
    fork(showRequestedUsers),
    fork(acceptUserConnection),
    fork(rejectUserConnection),
    fork(sendEmail),
  ]);
}
