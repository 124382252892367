import { setEnv } from './base';

const baseApi = 'https://api.mypetchart.vet';
const portalUrl = '';

const env = setEnv(baseApi, portalUrl);

const prodEnv = {
  ...env,
  environment: 'production',
};

export default prodEnv;
