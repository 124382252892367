import signinActions from './actions';
import { createReducer } from '../../helpers/reducer';
import { keyBy } from '../../helpers/core';

// ------------------------------
// Local util functions
// ------------------------------
function validate({ attr, value = '' }) {
  let error = '';
  const defaultErrorMessage =
    'Your password must be at least 5 characters.';

  if (attr === 'email') {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    if (!re.test(value)) {
      error = 'Please enter a valid email';
    }
  }

  if (attr === 'password') {
    if (!value || value.length < 5) {
      error = defaultErrorMessage;
    }
    // if (value && !/[a-z]/d.test(value)) {
    //   error = defaultErrorMessage;
    // }
    // if (value && !/[A-Z]/.test(value)) {
    //   error = defaultErrorMessage;
    // }
    // if (value && !/\W/.test(value)) {
    //   error = defaultErrorMessage;
    // }
  }
  return error;
}

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  email: {
    value: '',
    error: validate({ attr: 'email' }),
    name: 'email',
    touched: false,
  },
  password: {
    value: '',
    error: validate({ attr: 'password' }),
    name: 'password',
    touched: false,
  },
};

// ------------------------------
// Action Handlers
// ------------------------------
function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = {
    value,
    error: validate({ attr, value }),
    name: attr,
    touched: true,
  };

  return { ...state };
}

function onTouch(state, { payload }) {
  const { props } = payload;
  const propsArray = props.map(prop => state[prop]);

  const updatedState = keyBy(
    propsArray,
    prop => prop.name,
    prop => ({ ...prop, touched: true }),
  );

  return { ...state, ...updatedState };
}

function onClearState() {
  return { ...initState };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [signinActions.CHANGE_PROPERTY]: onChangeProperty,
  [signinActions.TOUCH]: onTouch,
  [signinActions.CLEAR_STATE]: onClearState,
});
