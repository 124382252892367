import forgotPasswordActions from './actions';
import { createReducer } from '../../helpers/reducer';
import { keyBy } from '../../helpers/core';

// ------------------------------
// Local util functions
// ------------------------------
function validate({ attr, value = '' }) {
  let error = '';
  if (attr === 'email') {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    if (!re.test(value)) {
      error = 'Please enter a valid email';
    }
  }

  return error;
}

// ------------------------------
// Initial State
// ------------------------------
const initState = {
  email: {
    value: '',
    error: validate({ attr: 'email' }),
    name: 'email',
    touched: false,
  },
  isSaveLoader: false,
  error: '',
  isSent: false,
};

// ------------------------------
// Action Handlers
// ------------------------------
function onChangeProperty(state, { payload }) {
  const { attr, value } = payload;

  state[attr] = {
    value,
    error: validate({ attr, value }),
    name: attr,
    touched: true,
  };

  return { ...state };
}

function onTouch(state, { payload }) {
  const { props } = payload;
  const propsArray = props.map(prop => state[prop]);

  const updatedState = keyBy(
    propsArray,
    prop => prop.name,
    prop => ({ ...prop, touched: true })
  );

  return { ...state, ...updatedState };
}

function onForgotPasswordRequest(state) {
  return {
    ...state,
    isSaveLoader: true,
  };
}

function onForgotPasswordSuccess(state) {
  return {
    ...state,
    isSaveLoader: false,
    isSent: true,
  };
}

function onForgotPasswordError(state, { payload }) {
  const { error } = payload;
  const { email } = state;
  return {
    ...state,
    email: {
      value: email.value,
      touched: true,
    },
    isSaveLoader: false,
    error: error,
  };
}

function onClearState() {
  return { ...initState };
}

// ------------------------------
// Exported reducer function.
// ------------------------------
export default createReducer(initState, {
  [forgotPasswordActions.CHANGE_PROPERTY]: onChangeProperty,
  [forgotPasswordActions.TOUCH]: onTouch,
  [forgotPasswordActions.CLEAR_STATE]: onClearState,
  [forgotPasswordActions.FORGOT_PASSWORD_REQUEST]: onForgotPasswordRequest,
  [forgotPasswordActions.FORGOT_PASSWORD_SUCCESS]: onForgotPasswordSuccess,
  [forgotPasswordActions.FORGOT_PASSWORD_ERROR]: onForgotPasswordError,
});
