import { takeEvery, all, fork, put } from 'redux-saga/effects';
import forgotPasswordActions from './actions';
import { callApi } from '../../helpers/api';

export function* forgotPasswordRequest() {
  yield takeEvery(
    forgotPasswordActions.FORGOT_PASSWORD_REQUEST,
    function* ({ payload }) {
      const result = yield callApi('vet/reset-password', 'POST', payload);
      if (result.status === 200) {
        yield put(forgotPasswordActions.forgotPasswordSuccess());
      } else {
        const message = result.data;
        yield put(
          forgotPasswordActions.forgotPasswordError({ error: message })
        );
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(forgotPasswordRequest)]);
}
