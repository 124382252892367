import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    .ant-alert{
      position: absolute;
      top:7%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
    }
    .ant-form-item{
      margin-bottom: 0;
    }
    .ant-select-dropdown{
      max-width: 310px!important;
    }
    .btn-primary {
      background: #003c5b;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      color: #f9fafc;
      line-height: 1.42;
      border-color: transparent;
      box-shadow: none;
      &.export{
        background: #E79E97;
        padding: 5px;
      }
      &.margin{
        margin: 5px 0;
      }
      &.secondary{
        background: rgba(89, 138, 179, 0.1);
        color: #598ab3;
      }
    }

    .btn-primary:active, .btn-primary:hover, .btn-primary:focus {
      background: #003c5b;
      color: #f9fafc;
      border-color: transparent;
      box-shadow: none;
      &.export{
        background: #E79E97;
      }
      &.secondary{
        background: rgba(89, 138, 179, 0.1);
        color: #598ab3;
      }
    }

    .btn-primary.export:disabled{
      opacity: .5;
    }

    [ant-click-animating-without-extra-node='true']::after {
      display: none;
    }

    .custom-modal{
      .ant-modal-content{
        border-radius: 10px;
      }
      .ant-modal-header{
        border-radius: 10px 10px 0 0;
      }
      .ant-modal-title{
        text-align: center;
        color: #003C5B;
        font-weight: 600;
        font-size: 16px
      }
      &.header-btns{
        .ant-modal-title{
          display: flex;
          justify-content: space-between;
          .title{
            margin: 0;
            text-align: left;
            flex-basis: 50%;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.4;
            color: #003C5B;
          }
        }
        .ant-modal-title > div > button:first-child{
          margin-right: 10px;
        }
      }
      .ant-modal-body{
        .body-dialog-container{
          height: auto;
          display: grid;
          column-gap: 25px;
          grid-template-columns: 1fr 2fr;
        }
        .right-block {
          display: grid;
          grid-template-columns: 2fr 2fr;
        }
        .form-field-label {
          padding-top: 10px;
          color: #003C5B;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .client-detail {
          padding-left: 5px;
          margin-bottom: 0;
          max-width: 270px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &-activity{
            font-weight: 600;
            font-size: 13px;
            margin-bottom: 0;
          }
        }
        .client-photo {
          max-width: 100%;
          padding: 10px;
        }
        .empty {
          background-color: #E6E6E6;
          height: 80%;
          margin:10px;
        }
        .content{
          padding: 0 20px;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          color: #888;
        }
        .pet-info{
          display: grid;
          grid-template-columns: 0.5fr repeat(2, 0.7fr) 2.5fr  1fr;
          grid-template-rows: 1fr;
          align-items: center;
          grid-column-gap: 10px;
        }
        .user-info{
          display: grid;
          grid-template-columns: repeat(2, 1.8fr) 2fr;
          grid-template-rows: 1fr;
          grid-column-gap: 0;
          grid-row-gap: 0;
        }
        .info-item-head{
          font-weight: 600;
          font-size: 12px;
          line-height: 1.3;
          color: #598AB3;
        }
        .info-item.overflow-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 150px;
          &.email {
            max-width: 300px;
          }
          &.name{
            max-width: 200px;
          }
        }
        .media{
          display: flex;
          gap: 5px;
        }
        .video{
          width: 100%;
        }
      }
      .ant-modal-footer{
        text-align: center;
        display: flex;
        justify-content: space-around;
        .ant-btn{
          display: block!important;
          text-align: center;
          height: 44px;
          width: 180px;
          font-weight: 500;
          font-size: 14px;
          border-radius: 8px;
          &.cancel{
            border: 1px solid #4F4F4F !important;
            color: #4F4F4F;
            background: #FFFFFF;
          }
          &.confirm{
            background: #003C5B;
          }
        }
      }
      .confirm-text{
        padding: 0 10%;
        font-size: 16px;
        line-height: 1.4;
        text-align: center;
        color: #4F4F4F;
      }
      textarea{
        resize: none;
      }
      .custom-form{
        .ant-form-item{
          margin-bottom: 10px;
          .ant-input{
            border-radius: 4px;
          }
          &.margin-bottom{
            margin-bottom: 30px;
          }
        }
        .ant-form-item-label label{
          margin: 0;
          font-weight: 600;
          color: #003C5B;
          font-size: 14px;
        }
        .flex{
          display: flex;
          justify-content: space-between;
        }
        .ant-btn{
          background: #003C5B;
          color: #FFFFFF;
          font-weight: 600;
          font-size: 12px;
          line-height: 1.71;
          &.enabled{
            background: #60b358;
          }
          &.disabled{
            background: #c91d12;
          }
          &.reset{
            background: #5895CB;
          }
          &.center{
            display: block!important;
            margin: 0 auto;
            width: 40%;
            height: 35px;
          }
        }
        .ant-btn:disabled{
          background: rgba(0,0,0,.26);
        }
        .avatar{
          display: block;
          margin: 0 auto;
        }
        label[for='image'] {
          display: block;
          text-align: center;
          font-size: 14px;
          line-height: 1.42857;
          color: #aaa;
          font-weight: 400;
        }
        .grid{
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, 1fr);
          grid-column-gap: 15px;
          grid-row-gap: 0;
        }
        .custom-input{
          border: 1px solid rgb(217, 217, 217);
          min-height: 32px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          padding: 4px 11px;
          margin-bottom: 7px;
          .overflow-text {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 300px;
          }
        }
        .hidden{
          margin-bottom: 5px;
          .ant-form-item-control{
            display: none;
          }
        }
        .custom-tag{
          height: 30px;
          line-height: 30px;
          margin: 3px;
          .overflow-text {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 300px;
          }
        }
        .word-counter{
          position: absolute;
          right: 20px;
          &.more{
            color:green;
          }
          &.normal{
            color: orange;
          }
          &.less{
            color:red;
          }
        }
      }

      .title{
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        color: #000000;
      }

      .message-text{
        text-align: center;
        font-size: 20px;
      }

      .footer{
        text-align: center;
        &-text{
          font-weight: 500;
          font-size: 14px;
          margin:  10px 30px;
        }
        .store-icons{
          margin: 10px 0;
        }
      }

      &.edit-profile{

        .last-login{
          border: none;
          background: transparent;
          color: black;
        }
      }
    }
    .scroll{
      position: relative;
    }
  }
`;

export default GlobalStyle;
