const ArrowRightIcon = () => (
  <svg
    width="0.8em"
    height="0.8em"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9.6333L5.13333 5.49998L1 1.36663"
      stroke="#598AB3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRightIcon;
