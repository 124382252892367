import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Form, Input, Modal, Select } from 'antd';

import actions from './actions';
import useModal from '../../helpers/useModal';
import ConfirmDialogModal from '../ConfirmDialogModal/ConfirmDialogModal';
import helpers from '../../helpers/helperFunctions';
import standardLogo from '../../assets/icons/clinics.svg';
import GlobalStyle from '../../GlobalStyle';
import PreviewInviteEmailModal from '../../shared/modals/PreviewInviteEmailModal';

let formDataLogo;

const { Option } = Select;

const EditClinicModal = ({ close, modalOptions }) => {
  const dispatch = useDispatch();

  const { edit, clinic } = modalOptions;

  const logo = clinic && clinic.logo;

  const [clinicLogo, setClinicLogo] = useState(logo ? logo : '');

  const [disabledSave, setDisabledSave] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);

  let imageInput = useRef(null);

  const [form] = Form.useForm();

  const addImage = () => {
    imageInput.current.click();
  };

  const onImageChangeHandler = event => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onload = ev => {
        setClinicLogo(URL.createObjectURL(selectedFile));
      };

      reader.readAsDataURL(selectedFile);

      formDataLogo = selectedFile;

      if (!form.getFieldsError().some(({ errors }) => errors.length)) {
        setDisabledSave(false);
      }
    }
  };

  const editClinicHandler = value => {
    const difference = helpers.findDifference(clinic, value);

    const editData = new FormData();

    for (const key in difference) {
      const element = difference[key];
      editData.append(key, element);
    }

    editData.append('clinic', clinic._id);

    if (formDataLogo) {
      editData.append('logo', formDataLogo, formDataLogo.name);
    }

    dispatch(
      actions.editClinicData({
        editData,
        dispatch,
      })
    );

    close();
  };

  const addClinicHandler = value => {
    dispatch(
      actions.addClinicRequest({
        ...value,
      })
    );

    close();
  };

  const isDataIdentical = () => {
    const formValues = form.getFieldsValue();
    if (edit) {
      if (
        (!formDataLogo &&
          formValues.name === clinic.name &&
          formValues.email === clinic.email &&
          formValues.address === clinic.address &&
          formValues.city === clinic.city &&
          formValues.phone === clinic.phone &&
          formValues.state === clinic.state &&
          formValues.website === clinic.website &&
          formValues.zip === clinic.zip) ||
        form.getFieldsError().some(({ errors }) => errors.length)
      ) {
        setDisabledSave(true);
        return;
      }
      setDisabledSave(false);
    } else {
      if (form.getFieldsError().some(({ errors }) => errors.length)) {
        setDisabledSave(true);
        return;
      }
      setDisabledSave(false);
    }
  };

  const closeModalHandler = () => {
    if (!disabledSave) {
      setIsModalVisible(true);
    } else {
      close();
    }
  };

  const confirmModalCloseHandler = () => {
    setIsModalVisible(false);
    close();
  };

  const {
    modalOptions: previewInviteModalOptions,
    showModal: showPreviewInviteModal,
    closeModal: closePreviewInviteModal,
  } = useModal({
    clinicInfo: {},
    preview: false,
  });

  return (
    <>
      <GlobalStyle />
      <Modal
        width={600}
        className="custom-modal"
        title={edit ? 'Edit Clinic' : 'Invite New Clinic'}
        visible={modalOptions.isModalVisible}
        onCancel={closeModalHandler}
        footer={null}
      >
        <Form
          layout="vertical"
          className="custom-form"
          form={form}
          onFinish={edit ? editClinicHandler : addClinicHandler}
          onFieldsChange={isDataIdentical}
        >
          {edit && (
            <Form.Item label="Photo" htmlFor="image" className="field-row-one">
              <div onClick={addImage}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={imageInput}
                  onChange={onImageChangeHandler}
                  accept="image/*"
                />
                {clinicLogo ? (
                  <Avatar className="avatar" size={100} src={clinicLogo} />
                ) : (
                  <Avatar className="avatar" size={50} src={standardLogo} />
                )}
              </div>
            </Form.Item>
          )}
          <div className="grid">
            <Form.Item
              label="Clinic"
              name="name"
              initialValue={edit ? clinic.name : null}
              rules={[
                {
                  required: true,
                  message: "Please input Clinic's name!",
                },
              ]}
            >
              <Input placeholder="Clinic Name" />
            </Form.Item>
            <Form.Item
              initialValue={edit ? clinic.email : null}
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Please input a valid email',
                },
                {
                  required: true,
                  message: "Please input Clinic's e-mail!",
                },
              ]}
            >
              <Input placeholder="Email" disabled={edit} />
            </Form.Item>
            <Form.Item
              label="Specialization"
              name="type"
              initialValue={edit ? clinic.type : null}
              rules={[
                {
                  required: true,
                  message: "Please select clinic's specialization",
                },
              ]}
            >
              <Select
                placeholder="Select clinic's specialization"
                disabled={edit}
              >
                <Option value="pets">Pets</Option>
                <Option value="equine">Equine</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Website"
              name="website"
              initialValue={edit ? clinic.website : ''}
            >
              <Input placeholder="Website" />
            </Form.Item>
            <Form.Item
              initialValue={edit ? clinic.phone : ''}
              label="Phone"
              name="phone"
              rules={[
                {
                  pattern: new RegExp(
                    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
                  ),
                  message: 'Please input a valid number',
                },
              ]}
            >
              <Input placeholder="Phone" />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              initialValue={edit ? clinic.address : ''}
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              initialValue={edit ? clinic.city : ''}
            >
              <Input placeholder="City" />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              initialValue={edit ? clinic.state : ''}
            >
              <Input placeholder="State" />
            </Form.Item>
            <Form.Item
              label="Zip"
              name="zip"
              initialValue={edit ? clinic.zip : ''}
            >
              <Input placeholder="Zip" />
            </Form.Item>
            <Form.Item
              style={{ fontSize: '12px' }}
              label="Link to share invite to connect to clinic"
              name="link"
              initialValue={clinic.link || null}
            >
              <Input placeholder="Link" readOnly />
            </Form.Item>
            {!edit && (
              <>
                <Form.Item
                  label="Admin Name"
                  name="adminName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Admin's name!",
                    },
                  ]}
                >
                  <Input placeholder="Admin Name" />
                </Form.Item>
                <Form.Item
                  label="Admin Email"
                  name="adminEmail"
                  rules={[
                    {
                      type: 'email',
                      message: 'Please input a valid email',
                    },
                    {
                      required: true,
                      message: "Please input Clinic's e-mail!",
                    },
                  ]}
                >
                  <Input placeholder="Admin Email" />
                </Form.Item>
              </>
            )}
          </div>
          <div className="flex">
            <Form.Item>
              <Button htmlType="submit" disabled={disabledSave}>
                SAVE
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                disabled={!edit}
                onClick={() =>
                  showPreviewInviteModal({
                    clinicInfo: {
                      clinicName: clinic.name,
                      clinicId: clinic._id,
                    },
                    preview: false,
                  })
                }
              >
                CONFIGURE INVITE
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {isModalVisible && (
        <ConfirmDialogModal
          isModalVisible={isModalVisible}
          title="Confirm close"
          text="You’re about to cancel your changes. Are you sure you want to continue?"
          cancel={() => setIsModalVisible(false)}
          confirm={confirmModalCloseHandler}
        />
      )}
      {previewInviteModalOptions.isModalVisible && (
        <PreviewInviteEmailModal
          modalOptions={previewInviteModalOptions}
          closeModal={closePreviewInviteModal}
        />
      )}
    </>
  );
};

export default EditClinicModal;
