const helpers = {
  capitalizeFirstLetter: function (str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else return '';
  },
  findDifference: function (a, b) {
    return Object.keys(b).reduce((_diff, key) => {
      if (a[key] === b[key]) return _diff;
      return {
        ..._diff,
        [key]: b[key],
      };
    }, {});
  },
};

export default helpers;
