const COMPONENT = 'CLINIC_DETAILS_';

const actions = {
  LOAD_CLINIC: `${COMPONENT}LOAD_CLINIC`,
  LOAD_CLINIC_SUCCESS: `${COMPONENT}LOAD_CLINIC_SUCCESS`,
  LOAD_CLINIC_ERROR: `${COMPONENT}LOAD_CLINIC_ERROR`,
  LOAD_STATISTIC: `${COMPONENT}LOAD_STATISTIC`,
  LOAD_STATISTIC_SUCCESS: `${COMPONENT}LOAD_STATISTIC_SUCCESS`,
  LOAD_STATISTIC_ERROR: `${COMPONENT}LOAD_STATISTIC_ERROR`,
  CHANGE_DATE: `${COMPONENT}CHANGE_DATE`,

  loadClinic: (data = {}) => ({
    type: actions.LOAD_CLINIC,
    payload: data,
  }),
  loadClinicSuccess: data => ({
    type: actions.LOAD_CLINIC_SUCCESS,
    payload: data,
  }),
  loadClinicError: data => ({
    type: actions.LOAD_CLINIC_ERROR,
    payload: data,
  }),
  loadStatistic: (data = {}) => ({
    type: actions.LOAD_STATISTIC,
    payload: data,
  }),
  loadStatisticSuccess: (data = {}) => ({
    type: actions.LOAD_STATISTIC_SUCCESS,
    payload: data,
  }),
  loadStatisticError: (data = {}) => ({
    type: actions.LOAD_STATISTIC_ERROR,
  }),
  changeStatisticDate: data => ({
    type: actions.CHANGE_DATE,
    payload: data,
  }),
};
export default actions;
