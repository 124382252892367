const UserIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.07495 15.6999V14.909C4.07495 11.9217 6.51259 9.49994 9.49995 9.49994V9.49994C12.4873 9.49994 14.925 11.9217 14.925 14.909V15.6999"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.4999 9.49993C11.212 9.49993 12.5999 8.11201 12.5999 6.39993C12.5999 4.68784 11.212 3.29993 9.4999 3.29993C7.78782 3.29993 6.3999 4.68784 6.3999 6.39993C6.3999 8.11201 7.78782 9.49993 9.4999 9.49993Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserIcon;
