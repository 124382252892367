const MomentIcon = () => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49986 2.1001C7.09658 2.1001 5.29172 3.09438 4.09998 4.07703V3.74009C4.09998 3.1878 3.65226 2.74009 3.09998 2.74009C2.54769 2.74009 2.09998 3.1878 2.09998 3.74009V6.65843C2.09998 7.21072 2.54769 7.65843 3.09998 7.65843H4.38635H5.94152C6.49381 7.65843 6.94152 7.21072 6.94152 6.65843C6.94152 6.10615 6.49381 5.65843 5.94152 5.65843H5.32624L5.33973 5.64716C6.28131 4.86168 7.66242 4.1001 9.49986 4.1001C12.5033 4.1001 14.8997 6.51692 14.8997 9.49998L14.8998 9.51072C14.894 12.4823 12.4833 14.8935 9.5119 14.8999L9.49987 14.8999C7.15337 14.8999 5.15171 13.3995 4.40842 11.3028C4.22389 10.7823 3.65231 10.5099 3.13177 10.6944C2.61122 10.8789 2.33883 11.4505 2.52336 11.971C3.53903 14.8361 6.27147 16.8939 9.48586 16.8998L9.49993 16.8999C13.5849 16.8999 16.8998 13.5851 16.8998 9.50005L16.8997 9.48751C16.8931 5.41082 13.6089 2.1001 9.49986 2.1001ZM3.95622 7.17502L3.95678 7.1741L3.95648 7.17457L3.95622 7.17502ZM10.2629 6.62622C10.2629 6.07393 9.81518 5.62622 9.26289 5.62622C8.71061 5.62622 8.26289 6.07393 8.26289 6.62622V9.25019C8.26289 9.66706 8.40178 10.061 8.57822 10.3704C8.75437 10.6793 9.02187 10.9984 9.37878 11.2104L9.38028 11.2113L11.3616 12.3937C11.8358 12.6767 12.4497 12.5217 12.7328 12.0474C13.0158 11.5732 12.8608 10.9593 12.3865 10.6762L10.4042 9.49324C10.4019 9.49141 10.3938 9.48477 10.3813 9.47122C10.3609 9.44909 10.3373 9.41776 10.3156 9.37959C10.2938 9.34143 10.2788 9.30521 10.2702 9.27639C10.2649 9.25873 10.2633 9.24836 10.2629 9.2454V6.62622Z"
      fill="currentColor"
    />
  </svg>
);

export default MomentIcon;
