const PetNotificationIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49175 20.7638C3.30175 23.0517 5.02758 24.92 7.32342 24.92H11.1155C13.6963 24.92 15.4696 22.8379 15.0421 20.2888C14.5909 17.6129 12.0101 15.42 9.29463 15.42C6.34966 15.42 3.73716 17.8267 3.49175 20.7638Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.49916 12.6384C10.5922 12.6384 11.4783 11.7523 11.4783 10.6592C11.4783 9.56616 10.5922 8.68005 9.49916 8.68005C8.40609 8.68005 7.51999 9.56616 7.51999 10.6592C7.51999 11.7523 8.40609 12.6384 9.49916 12.6384Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1533 15.1666C17.0277 15.1666 17.7367 14.4577 17.7367 13.5833C17.7367 12.7088 17.0277 11.9999 16.1533 11.9999C15.2789 11.9999 14.57 12.7088 14.57 13.5833C14.57 14.4577 15.2789 15.1666 16.1533 15.1666Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.84334 15.1667C3.71779 15.1667 4.42668 14.4578 4.42668 13.5833C4.42668 12.7089 3.71779 12 2.84334 12C1.96889 12 1.26001 12.7089 1.26001 13.5833C1.26001 14.4578 1.96889 15.1667 2.84334 15.1667Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5833 6.16659C16.4577 6.16659 17.1667 5.4577 17.1667 4.58325C17.1667 3.7088 16.4577 2.99992 15.5833 2.99992C14.7089 2.99992 14 3.7088 14 4.58325C14 5.4577 14.7089 6.16659 15.5833 6.16659Z"
      fill="#F64744"
      stroke="#F64744"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PetNotificationIcon;
