import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'antd';
import actions from '../../redux/message/actions';
import GlobalStyle from '../../GlobalStyle';

const CustomMessage = () => {
  const { isOpen, message, isError } = useSelector(
    state => state.MessageReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(actions.hideMessage());
      }, 3000);
    }
  }, [message,dispatch]);

  return (
    <>
      <GlobalStyle />
      {isOpen && message && (
        <Alert
          banner={true}
          message={message}
          type={isError ? 'error' : 'success'}
          showIcon
        />
      )}
    </>
  );
};

export default CustomMessage;
