const COMPONENT = 'PROFILE_';

const actions = {
  LOAD_USER_PROFILE: `${COMPONENT}LOAD_USER_PROFILE`,
  LOAD_USER_PROFILE_SUCCESS: `${COMPONENT}LOAD_USER_PROFILE_SUCCESS`,
  LOAD_USER_PROFILE_FAIL: `${COMPONENT}LOAD_USER_PROFILE_FAIL`,
  EDIT_USER_PROFILE: `${COMPONENT}EDIT_USER_PROFILE`,
  EDIT_USER_PROFILE_SUCCESS: `${COMPONENT}EDIT_USER_PROFILE_SUCCESS`,
  EDIT_USER_PROFILE_FAIL: `${COMPONENT}EDIT_USER_PROFILE_FAIL`,


  loadProfile: () => ({
    type: actions.LOAD_USER_PROFILE,
  }),
  loadProfileSuccess: data => ({
    type: actions.LOAD_USER_PROFILE_SUCCESS,
    payload: data,
  }),
  loadProfileFail: data => ({
    type: actions.LOAD_USER_PROFILE_FAIL,
    payload: data,
  }),
  editProfile: data => ({
    type: actions.EDIT_USER_PROFILE,
    payload: data,
  }),
  editProfileSuccess: () => ({
    type: actions.EDIT_USER_PROFILE_SUCCESS,
  }),
  editProfileFail: () => ({
    type: actions.EDIT_USER_PROFILE_FAIL,
  }),

};
export default actions;
