import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../../helpers/api';
import moment from 'moment';

let api = new RestApi('statistics/monthly');

//Fill in all months if the statistics are incomplete
function mergeArrayObjects(arr) {
  const defaultMonthlyArray = [
    {
      name: 'Jan',
      count: 0,
    },
    {
      name: 'Feb',
      count: 0,
    },
    {
      name: 'Mar',
      count: 0,
    },
    {
      name: 'Apr',
      count: 0,
    },
    {
      name: 'May',
      count: 0,
    },
    {
      name: 'Jun',
      count: 0,
    },
    {
      name: 'Jul',
      count: 0,
    },
    {
      name: 'Aug',
      count: 0,
    },
    {
      name: 'Sep',
      count: 0,
    },
    {
      name: 'Oct',
      count: 0,
    },
    {
      name: 'Nov',
      count: 0,
    },
    {
      name: 'Dec',
      count: 0,
    },
  ];

  const mergedArray = [...defaultMonthlyArray];
  arr.forEach(item => {
    mergedArray[item.month - 1].count = item.count;
  });
  return mergedArray;
}

export function* loadPetOwnersReport() {
  yield takeEvery(actions.LOAD_PET_OWNERS_REPORT, function* ({ payload }) {
    const modifiedQuery = {
      ...payload,
      month: payload.year.format('M'),
      year: payload.year.format('YYYY'),
    };
    const result = yield api.load(modifiedQuery);
    if (result.status === 200) {
      if (payload.interval === 'monthly') {
        const { totalStatistic, statistic, total } = result.data;
        const monthlyData = totalStatistic.map(item => ({
          count: item.usersCount,
          month: item.month,
        }));
        const tableData = statistic.map(clinicItem => {
          const { clinicName, clinicId, statistic } = clinicItem;
          const data = {
            clinicName: clinicName,
            clinicId: clinicId,
            statistic: {},
          };
          statistic.forEach(statisticItem => {
            data.statistic[moment(statisticItem.month, 'M').format('MMM')] =
              statisticItem.usersCount;
          });

          return data;
        });
        yield put(
          actions.loadReportSuccess({
            chartData: mergeArrayObjects(monthlyData),
            tableData: tableData,
            pagination: { total: total },
          })
        );
      } else {
        const { statistic } = result.data;
        yield put(
          actions.loadReportSuccess({
            dailyData: statistic,
          })
        );
      }
    }
    //TODO: add load report data error handling
  });
}
export default function* rootSaga() {
  yield all([fork(loadPetOwnersReport)]);
}
