const messageActions = {
  SHOW_SUCCESS_MESSAGE: 'SHOW_SUCCESS_MESSAGE',
  SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE',
  HIDE_MESSAGE: 'HIDE_MESSAGE',

  showSuccessMessage: data => ({
    type: messageActions.SHOW_SUCCESS_MESSAGE,
    payload: data,
  }),
  showErrorMessage: data => ({
    type: messageActions.SHOW_ERROR_MESSAGE,
    payload: data,
  }),
  hideMessage: () => ({
    type: messageActions.HIDE_MESSAGE,
  }),
};

export default messageActions;
