import { createReducer } from '../../../../helpers/reducer';
import actions from './actions';

const initialState = {
  tableData: [],
  pagination: {
    limit: 10,
    page: 1,
    total: 0,
  },
  loading: false,
};

function showRequestedUsers(state) {
  return {
    ...state,
    loading: true,
  };
}

function showRequestedUsersSuccess(state, { payload }) {
  return {
    ...state,
    ...payload,
    pagination: { ...state.pagination, ...payload.pagination },
    loading: false,
  };
}

function showRequestedUsersError(state) {
  return {
    ...state,
    loading: false,
  };
}

function onChangePagination(state, { payload }) {
  return { ...state, pagination: payload };
}

function acceptUserConnectionRequest(state) {
  return { ...state, loading: true };
}

function acceptUserConnectionSuccess(state) {
  return { ...state, loading: false };
}

function acceptUserConnectionError(state) {
  return { ...state, loading: false };
}

function rejectUserConnectionRequest(state) {
  return { ...state, loading: true };
}

function rejectUserConnectionSuccess(state) {
  return { ...state, loading: false };
}

function rejectUserConnectionError(state) {
  return { ...state, loading: false };
}

export default createReducer(initialState, {
  [actions.SHOW_REQUESTED_USERS_REQUEST]: showRequestedUsers,
  [actions.SHOW_REQUESTED_USERS_SUCCESS]: showRequestedUsersSuccess,
  [actions.SHOW_REQUESTED_USERS_ERROR]: showRequestedUsersError,
  [actions.CHANGE_REQUESTED_USERS_PAGINATION]: onChangePagination,
  [actions.ACCEPT_USER_CONNECTION_REQUEST]: acceptUserConnectionRequest,
  [actions.ACCEPT_USER_CONNECTION_SUCCESS]: acceptUserConnectionSuccess,
  [actions.ACCEPT_USER_CONNECTION_ERROR]: acceptUserConnectionError,
  [actions.REJECT_USER_CONNECTION_REQUEST]: rejectUserConnectionRequest,
  [actions.REJECT_USER_CONNECTION_SUCCESS]: rejectUserConnectionSuccess,
  [actions.REJECT_USER_CONNECTION_ERROR]: rejectUserConnectionError,
});
