const vetInfoActions = {
  LOAD_VET_INFO: 'LOAD_VET_INFO',
  LOAD_VET_INFO_SUCCESS: 'LOAD_VET_INFO_SUCCESS',
  LOAD_VET_INFO_ERROR: 'LOAD_VET_INFO_ERROR',
  CHANGE_VET_INFO_TABLE_PAGINATION: 'CHANGE_VET_INFO_TABLE_PAGINATION',
  SEARCH_VET: 'SEARCH_VET',
  FILTER_VET: 'FILTER_VET',
  CLEAR_STATE: 'CLEAR_STATE',
  INVITE_DOCTOR_REQUEST: 'INVITE_DOCTOR_REQUEST',
  INVITE_DOCTOR_SUCCESS: 'INVITE_DOCTOR_SUCCESS',
  INVITE_DOCTOR_ERROR: 'INVITE_DOCTOR_ERROR',
  EDIT_DOCTOR_REQUEST: 'EDIT_DOCTOR_REQUEST',
  EDIT_DOCTOR_SUCCESS: 'EDIT_DOCTOR_SUCCESS',
  EDIT_DOCTOR_ERROR: 'EDIT_DOCTOR_ERROR',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  REINVITE_DOCTOR_REQUEST: 'REINVITE_DOCTOR_REQUEST',
  REINVITE_DOCTOR_SUCCESS: 'REINVITE_DOCTOR_SUCCESS',
  REINVITE_DOCTOR_ERROR: 'REINVITE_DOCTOR_ERROR',

  loadVetInfo: data => ({
    type: vetInfoActions.LOAD_VET_INFO,
    payload: data,
  }),
  loadVetInfoSuccess: data => ({
    type: vetInfoActions.LOAD_VET_INFO_SUCCESS,
    payload: data,
  }),

  loadVetInfoError: data => ({
    type: vetInfoActions.LOAD_VET_INFO_ERROR,
    payload: data,
  }),
  changePagination: data => ({
    type: vetInfoActions.CHANGE_VET_INFO_TABLE_PAGINATION,
    payload: data,
  }),
  searchVet: data => ({
    type: vetInfoActions.SEARCH_VET,
    payload: data,
  }),
  changOrderBy: data => ({
    type: vetInfoActions.FILTER_VET,
    payload: data,
  }),
  clearState: () => ({
    type: vetInfoActions.CLEAR_STATE,
  }),
  inviteDoctorRequest: data => ({
    type: vetInfoActions.INVITE_DOCTOR_REQUEST,
    payload: data,
  }),
  inviteDoctorSuccess: () => ({
    type: vetInfoActions.INVITE_DOCTOR_SUCCESS,
  }),
  inviteDoctorError: () => ({
    type: vetInfoActions.INVITE_DOCTOR_ERROR,
  }),
  editDoctorRequest: data => ({
    type: vetInfoActions.EDIT_DOCTOR_REQUEST,
    payload: data,
  }),
  editDoctorError: () => ({
    type: vetInfoActions.EDIT_DOCTOR_ERROR,
  }),
  editDoctorSuccess: () => ({
    type: vetInfoActions.EDIT_DOCTOR_SUCCESS,
  }),
  resetPasswordRequest: data => ({
    type: vetInfoActions.RESET_PASSWORD_REQUEST,
    payload: data
  }),
  resetPasswordSuccess: () => ({
    type: vetInfoActions.RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordError: () => ({
    type: vetInfoActions.RESET_PASSWORD_ERROR,
  }),
  reinviteDoctorRequest: data => ({
    type: vetInfoActions.REINVITE_DOCTOR_REQUEST,
    payload: data,
  }),
  reinviteDoctorSuccess: () => ({
    type: vetInfoActions.REINVITE_DOCTOR_SUCCESS,
  }),
  reinviteDoctorError: () => ({
    type: vetInfoActions.REINVITE_DOCTOR_ERROR,
  }),
};

export default vetInfoActions;
