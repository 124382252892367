const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOG_REQUEST: 'LOG_REQUEST',

  log: data => ({
    type: actions.LOG_REQUEST,
    payload: data
  }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: data => ({
    type: actions.LOGIN_REQUEST,
    payload: data,
  }),
  loginSuccess: data => ({
    type: actions.LOGIN_SUCCESS,
    payload: data,
  }),
  loginError: data => ({
    type: actions.LOGIN_ERROR,
    payload: data,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  })
};
export default actions;
