import actions from './actions';
import { createReducer } from '../../helpers/reducer';
import { getAuthData } from '../../helpers/auth';

const authData = getAuthData();

const initState = {
  // idToken: authData ? authData.idToken : null,
  accessToken: authData ? authData.accessToken : null,
  // claims: authData ? authData.claims : null,
  // profile: authData ? authData.profile : null,
  error: '',
  isSaveLoader: false,
  role: authData ? authData.role : null,
};

function onLoginSuccess(state, { payload }) {
  const { role, accessToken } = payload;
  return {
    ...state,
    role,
    accessToken,
    isSaveLoader: false,
    error: '',
  };
}

function onLoginError(state, { payload }) {
  return {
    ...state,
    accessToken: null,
    isSaveLoader: false,
    error: payload.error,
  };
}

function onLoginRequest(state) {
  return {
    ...state,
    isSaveLoader: true,
  };
}

function onLogout(state) {
  return {
    ...state,
    accessToken: null,
    role: null,
    error: '',
  };
}


export default createReducer(initState, {
  [actions.LOGIN_SUCCESS]: onLoginSuccess,
  [actions.LOGIN_ERROR]: onLoginError,
  [actions.LOGOUT]: onLogout,
  [actions.LOGIN_REQUEST]: onLoginRequest,
});
