import { createReducer } from '../../helpers/reducer';
import actions from './actions';

const initialState = {
  momentsList: {},
  users: [],
  pagination: {
    limit: 50,
    page: 1,
    total: 0,
  },
  filter: {
    clientId: '',
    petId: '',
    dateFrom: '',
    dateTo: '',
    keywords: '',
    period: '',
  },
  loading: false,
  isFetchingUsers: false,
  full: false,
  sort: 'All',
  type: 'moment',
  messagesList: [],
  tableData: [],
  total: 0
};

function onLoadMomentsInfo(state) {
  return {
    ...state,
    loading: true,
  };
}
function onLoadMomentsInfoSuccess(state, { payload }) {
  return {
    ...state,
    ...payload,
    pagination: { ...state.pagination, ...payload.pagination },
    loading: false,
  };
}
function onLoadMomentsInfoError(state) {
  return { ...state, loading: false };
}

function onChangePagination(state, { payload }) {
  return { ...state, pagination: payload };
}

function filterMoments(state, { payload }) {
  return {
    ...state,
    filter: payload,
    pagination: { ...initialState.pagination },
  };
}

function onSortMoments(state, { payload }) {
  return { ...state, sort: payload };
}

function getUsersList(state) {
  return { ...state, isFetchingUsers: true };
}

function getUsersListSuccess(state, { payload }) {
  return { ...state, ...payload, isFetchingUsers: false };
}

function onClearState() {
  return { ...initialState };
}

function onClearFilterState(state) {
  return { ...state, filter: initialState.filter };
}

function onSendMessageRequest(state) {
  return { ...state, loading: true };
}

function onSendMessageSuccess(state) {
  return { ...state, loading: false };
}

function onSendMessageError(state) {
  return { ...state, loading: false };
}

function onMarkMomentsRequest(state) {
  return { ...state, loading: true };
}

function onMarkMomentsSuccess(state) {
  return { ...state, loading: false };
}

function onMarkMomentsError(state) {
  return { ...state, loading: false };
}

function onChangeTypeRequest(state, { payload }) {
  return { ...state, type: payload, loading: true };
}

function onChangeTypeSuccess(state) {
  return { ...state, loading: false };
}

function getMomentMessages(state) {
  return { ...state, loading: true };
}

function getMomentMessagesSuccess(state, {payload}) {
  return { ...state, ...payload, loading: false };
}

export default createReducer(initialState, {
  [actions.LOAD_MOMENTS_INFO]: onLoadMomentsInfo,
  [actions.LOAD_MOMENTS_INFO_SUCCESS]: onLoadMomentsInfoSuccess,
  [actions.LOAD_MOMENTS_INFO_ERROR]: onLoadMomentsInfoError,
  [actions.CHANGE_MOMENTS_INFO_TABLE_PAGINATION]: onChangePagination,
  [actions.FILTER_MOMENTS_INFO_TABLE]: filterMoments,
  [actions.GET_USERS_LIST]: getUsersList,
  [actions.GET_USERS_LIST_SUCCESS]: getUsersListSuccess,
  [actions.CLEAR_STATE]: onClearState,
  [actions.CLEAR_FILTER_STATE]: onClearFilterState,
  [actions.SEND_MESSAGE_REQUEST]: onSendMessageRequest,
  [actions.SEND_MESSAGE_SUCCESS]: onSendMessageSuccess,
  [actions.SEND_MESSAGE_ERROR]: onSendMessageError,
  [actions.MARK_MOMENTS_REQUEST]: onMarkMomentsRequest,
  [actions.MARK_MOMENTS_SUCCESS]: onMarkMomentsSuccess,
  [actions.MARK_MOMENTS_ERROR]: onMarkMomentsError,
  [actions.SORT_MOMENTS]: onSortMoments,
  [actions.CHANGE_TYPE_REQUEST]: onChangeTypeRequest,
  [actions.CHANGE_TYPE_SUCCESS]: onChangeTypeSuccess,
  [actions.SORT_MOMENTS]: onSortMoments,
  [actions.GET_MOMENT_MESSAGES]: getMomentMessages,
  [actions.GET_MOMENT_MESSAGES_SUCCESS]: getMomentMessagesSuccess,
});
