import { history } from '../redux/store';
import axios from 'axios';
import { clearAuthData, clearUserPermissionsData } from './auth';

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const checkStatus = response => {
  if (response.status === 500) history.push('/500');
  if (
    response.status === 401 &&
    (response.data === 'Unauthorized' || response.data === 'no_super_admin')
  ) {
    clearAuthData();
    clearUserPermissionsData();
    window.location.pathname = '/';
  }

  return response;
};

export default async function request(url, options) {
  options.mode = 'cors';
  try {
    return await axios(url, options);
  } catch (e) {
    console.error(e);
    checkStatus(e.response);
    return e.response;
  }
}
