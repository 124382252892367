import { all, takeEvery, fork, put } from 'redux-saga/effects';

import { callApi } from '../../helpers/api';
import profileActions from './actions';
import messageActions from '../message/actions';


export function* loadProfile() {
  yield takeEvery(profileActions.LOAD_USER_PROFILE, function* () {
    const result = yield callApi('vet/me', 'GET');
    if (result.status === 200) {
      yield put(profileActions.loadProfileSuccess(result.data));
    } else {
      // TODO: add notification on request fail
    }
  });
}

export function* editProfile() {
  yield takeEvery(profileActions.EDIT_USER_PROFILE, function* ({ payload }) {
    const { dispatch, editData } = payload;
    const result = yield callApi('vet/edit-vet', 'PATCH', editData);
    if (result.status === 200) {
      yield put(profileActions.editProfileSuccess());
      yield put(
        messageActions.showSuccessMessage('Doctor has been edited successfully')
      );
      dispatch(profileActions.loadProfile());
    }
  });
}



export default function* rootSaga() {
  yield all([
    fork(loadProfile),
    fork(editProfile),
  ]);
}
