const clientModalActions = {
  LOAD_NOTIFICATION_BY_CLIENT: 'LOAD_NOTIFICATION_BY_CLIENT',
  LOAD_NOTIFICATION_BY_CLIENT_SUCCESS: 'LOAD_NOTIFICATION_BY_CLIENT_SUCCESS',
  LOAD_NOTIFICATION_BY_CLIENT_ERROR: 'LOAD_NOTIFICATION_BY_CLIENT_ERROR',
  REMOVE_CLIENT_REQUEST: 'REMOVE_CLIENT_REQUEST',
  REMOVE_CLIENT_SUCCESS: 'REMOVE_CLIENT_SUCCESS',
  REMOVE_CLIENT_ERROR: 'REMOVE_CLIENT_ERROR',
  INVITE_CLIENT_REQUEST: 'INVITE_CLIENT_REQUEST',
  INVITE_CLIENT_SUCCESS: 'INVITE_CLIENT_SUCCESS',
  INVITE_CLIENT_ERROR: 'INVITE_CLIENT_ERROR',
  UNLINK_CLIENT_REQUEST: 'UNLINK_CLIENT_REQUEST',
  UNLINK_CLIENT_SUCCESS: 'UNLINK_CLIENT_SUCCESS',
  UNLINK_CLIENT_ERROR: 'UNLINK_CLIENT_ERROR',
  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR',

  loadNotificationByClient: data => ({
    type: clientModalActions.LOAD_NOTIFICATION_BY_CLIENT,
    payload: data,
  }),

  loadNotificationByClientSuccess: data => ({
    type: clientModalActions.LOAD_NOTIFICATION_BY_CLIENT_SUCCESS,
    payload: data,
  }),

  loadNotificationByClientError: data => ({
    type: clientModalActions.LOAD_NOTIFICATION_BY_CLIENT_ERROR,
    payload: data,
  }),

  removeClient: data => ({
    type: clientModalActions.REMOVE_CLIENT_REQUEST,
    payload: data,
  }),

  removeClientSuccess: data => ({
    type: clientModalActions.REMOVE_CLIENT_SUCCESS,
    payload: data,
  }),

  removeClientError: data => ({
    type: clientModalActions.REMOVE_CLIENT_ERROR,
    payload: data,
  }),

  inviteClient: data => ({
    type: clientModalActions.INVITE_CLIENT_REQUEST,
    payload: data,
  }),

  inviteClientSuccess: data => ({
    type: clientModalActions.INVITE_CLIENT_SUCCESS,
    payload: data,
  }),

  inviteClientError: data => ({
    type: clientModalActions.INVITE_CLIENT_ERROR,
    payload: data,
  }),

  unlinkClient: data => ({
    type: clientModalActions.UNLINK_CLIENT_REQUEST,
    payload: data,
  }),

  unlinkClientSuccess: data => ({
    type: clientModalActions.UNLINK_CLIENT_SUCCESS,
    payload: data,
  }),

  unlinkClientError: data => ({
    type: clientModalActions.UNLINK_CLIENT_ERROR,
    payload: data,
  }),

  sendEmailRequest: data => ({
    type: clientModalActions.SEND_EMAIL_REQUEST,
    payload: data,
  }),
  sendEmailSuccess: () => ({
    type: clientModalActions.SEND_EMAIL_SUCCESS,
  }),
  sendEmailError: () => ({
    type: clientModalActions.SEND_EMAIL_ERROR,
  }),
};

export default clientModalActions;
