import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import preval from 'preval.macro';
import { ThemeProvider } from 'styled-components';
import { Provider, ErrorBoundary, LEVEL_WARN } from '@rollbar/react';
import environment from 'environment';

import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import theme from '../../settings/theme';
import AppHolder from './commonStyle';
import './global.css';
import Sidebar from '../Sidebar/Sidebar';
import ClinicInfoItem from '../../components/ClinicInfoItem/ClinicInfoItem';
import CustomMessage from '../../components/CustomMessage/CustomMessage';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;

const rollbarConfig = {
  accessToken: '71a5dec1e1134916b480852aa6a4ab58',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.environment,
};

export class App extends Component {
  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const { role } = this.props.auth;
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary level={LEVEL_WARN}>
          <ThemeProvider theme={theme.colors}>
            <AppHolder>
              <Layout style={{ overflowY: 'hidden' }}>
                <Sidebar logout={logout} />
                <Layout>
                  {role !== 'super_admin' && <ClinicInfoItem role={role} />}
                  <CustomMessage />
                  <Layout className="isoContentMainLayout">
                    <style>
                      {`
                .isoContentMainLayout {
                  height: ${height - 150}px;
                }
                .isomorphicContent {padding: '70px 0 0';}
                @media print {
                  .isoContentMainLayout{height: auto;}
                  .isomorphicContent {padding: '0';}
                  }
                `}
                    </style>
                    <Content
                      className="isomorphicContent"
                      style={{
                        flexShrink: '0',
                        padding: '20px 30px 0px 30px',
                        position: 'relative',
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>

                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'left',
                        color: 'rgba(0, 60, 91, 0.5)',
                      }}
                    >
                      {siteConfig.footerText}
                      <span style={{ float: 'right' }}>
                        Built date:
                        {preval`module.exports = new Date().toLocaleString();`}.{' '}
                      </span>
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height,
  }),
  { logout, toggleAll }
)(App);
