import { all, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import RestApi from '../../helpers/api';

let clinicApi = new RestApi('vet/clinic');
let statisticsApi = new RestApi('statistics/daily');



export function* loadClinic() {
  yield takeEvery(actions.LOAD_CLINIC, function* ({ payload }) {
    const { id } = payload;
    const result = yield clinicApi.loadItem(id);

    if (result.status === 200) {
      const {
        name,
        city,
        email,
        phoneNumber,
        zip,
        vets,
        address,
        state,
        status,
      } = result.data.clinic;

      yield put(
        actions.loadClinicSuccess({
          name,
          city,
          email,
          phone: phoneNumber,
          zip,
          staffMembers: vets.length,
          address,
          state,
          status,
          contactName: vets[0]?.name,
        })
      );
    }
    //TODO: add load report data error handling
  });
  yield takeEvery(actions.LOAD_STATISTIC, function* ({ payload }) {
    const { date, id } = payload;
    const result = yield statisticsApi.load({
      year: date.format('YYYY'),
      month: date.format('M'),
      clinicId: id,
    });

    if (result.status === 200) {
      const { dailyStatistic, monthlyStatistic } = result.data;
      yield put(
        actions.loadStatisticSuccess({ monthlyStatistic, dailyStatistic })
      );
    }

    //TODO: add load report data error handling
  });
}
export default function* rootSaga() {
  yield all([fork(loadClinic)]);
}
