import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Layout, Menu, Button, Typography, Avatar, Tooltip } from 'antd';
import Icon, { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import moment from 'moment';

import profileActions from '../../redux/profile/actions';
import { roleOptions } from './options';
import EditProfileModal from './EditProfileModal';
import ArrowLeftIcon from '../../components/Icons/ArrowLeftIcon';
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import UserIcon from '../../components/Icons/UserIcon';
import Logo from '../../components/Logo/Logo';
import PetNotificationIcon from '../../components/Icons/PetNotificationIcon';
import SidebarStyleWrapper from './Sidebar.style';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const Sidebar = ({ logout }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { role } = useSelector(state => state.Auth);

  const { name, profileImage, email, lastLogin, userId } = useSelector(
    state => state.UserProfile
  );

  const {
    clinic: { type, requestUsers },
  } = useSelector(state => state.ClinicInfo);

  const dispatch = useDispatch();

  const location = useLocation();

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const [editProfileModalOptions, setEditProfileModalOptions] = useState({
    isModalVisible: false,
    modalData: {},
  });

  const closeEditProfileModalHandler = () => {
    setEditProfileModalOptions({
      isModalVisible: false,
      modalData: {},
    });
  };

  const showEditProfileModalHandler = () => {
    setEditProfileModalOptions({
      isModalVisible: true,
      modalData: {
        email,
        name,
        lastLogin: moment
          .utc(lastLogin, 'YYYY MM DD, h:mm a')
          .local()
          .format('MMM DD,YYYY h:mm a'),
        profileImage,
        userId,
      },
    });
  };

  const sidebarToggle = () => {
    setCollapsed(collapsed => !collapsed);
  };

  useEffect(() => {
    dispatch(profileActions.loadProfile());
  }, [dispatch]);

  return (
    <>
      <SidebarStyleWrapper>
        <Sider
          collapsible
          className="sidebar"
          mode="inline"
          theme="light"
          onCollapse={onCollapse}
          collapsed={collapsed}
          trigger={null}
        >
          <Logo  />
          <Menu selectedKeys={[location.pathname.slice(1)]}>
            <Menu.Divider />
            {roleOptions
              .filter(({ permission }) => permission.includes(role))
              .map(option => {
                const { key, icon, label, externalLink } = option;
                const showNotice =
                  label === 'Clients' &&
                  requestUsers &&
                  requestUsers.length > 0 &&
                  collapsed;
                return (
                  <Menu.Item
                    key={key}
                    icon={
                      <Icon
                        style={{ transform: 'translateY(3px)' }}
                        component={() =>
                          showNotice ? <PetNotificationIcon /> : icon
                        }
                      />
                    }
                  >
                    {externalLink ? (
                      <a href={externalLink}>{label}</a>
                    ) : (
                      <NavLink
                        className="link"
                        to={{
                          pathname: `/${key}`,
                        }}
                      >
                        {label === 'Clients' &&
                        requestUsers &&
                        requestUsers.length > 0 ? (
                          <>
                            {label}
                            <sub className="req-users">
                              ({requestUsers.length} requests)
                            </sub>
                          </>
                        ) : (
                          label
                        )}
                      </NavLink>
                    )}
                  </Menu.Item>
                );
              })}
          </Menu>

          <div className="sidebar-footer">
            <div className="collapse-trigger">
              <Button
                onClick={sidebarToggle}
                className="collapse-trigger-btn"
                icon={collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
              />
              {collapsed ? null : (
                <Text
                  onClick={sidebarToggle}
                  className="collapse-trigger-label"
                >
                  Reduce
                </Text>
              )}
            </div>
            <Menu mode="inline" selectable={false}>
              <Menu.Divider />
              <SubMenu
                key="account-menu"
                icon={
                  profileImage ? (
                    <Avatar size={43} src={profileImage} />
                  ) : (
                    <Avatar
                      className="avatar-icon-shape"
                      size={35}
                      icon={<Icon component={UserIcon} />}
                    />
                  )
                }
                title={
                  <Tooltip title={name} mouseEnterDelay={0.3}>
                    <Text className="user-name" style={{ color: '#598AB3' }}>
                      {name}
                    </Text>
                  </Tooltip>
                }
                popupOffset={[8, -50]}
              >
                <Menu.Item
                  key="edit-account"
                  icon={<ProfileOutlined />}
                  onClick={showEditProfileModalHandler}
                >
                  Edit profile
                </Menu.Item>
                <Menu.Item
                  key="logout"
                  icon={<LogoutOutlined />}
                  onClick={() => dispatch(logout())}
                >
                  Logout
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Sider>
      </SidebarStyleWrapper>
      {editProfileModalOptions.isModalVisible && (
        <EditProfileModal
          closeModal={closeEditProfileModalHandler}
          modalOptions={editProfileModalOptions}
        />
      )}
    </>
  );
};

export default Sidebar;
