const clientInfoActions = {
  LOAD_CLIENT_INFO: 'LOAD_CLIENT_INFO',
  LOAD_CLIENT_INFO_SUCCESS: 'LOAD_CLIENT_INFO_SUCCESS',
  LOAD_CLIENT_INFO_ERROR: 'LOAD_CLIENT_INFO_ERROR',
  CHANGE_CLIENT_INFO_TABLE_PAGINATION: 'CHANGE_CLIENT_INFO_TABLE_PAGINATION',
  FILTER_CLIENT: 'FILTER_CLIENT',
  SEARCH_CLIENT: 'SEARCH_CLIENT',
  CLEAR_STATE: 'CLEAR_STATE',

  loadClientInfo: data => ({
    type: clientInfoActions.LOAD_CLIENT_INFO,
    payload: data,
  }),
  loadClientInfoSuccess: data => ({
    type: clientInfoActions.LOAD_CLIENT_INFO_SUCCESS,
    payload: data,
  }),
  loadClientInfoError: data => ({
    type: clientInfoActions.LOAD_CLIENT_INFO_ERROR,
    payload: data,
  }),
  changePagination: data => ({
    type: clientInfoActions.CHANGE_CLIENT_INFO_TABLE_PAGINATION,
    payload: data,
  }),
  changOrderBy: data => ({
    type: clientInfoActions.FILTER_CLIENT,
    payload: data,
  }),
  searchClient: data => ({
    type: clientInfoActions.SEARCH_CLIENT,
    payload: data,
  }),
  clearState: () => ({
    type: clientInfoActions.CLEAR_STATE,
  }),
};
export default clientInfoActions;
